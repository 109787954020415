var artistPersonsTableId = '#artist-persons-datatable';
var artistEventsTableId = '#artist-events-datatable';

function load_artists_datatable() {
    if (CURRENT_ACTION !== 'index') return;
    init_datatable('#artists-datatable', { 'search_area' : '#artist_search', 'order' : [[ 0, "asc" ]], 'min_filter_length' : 3 });
}

global.artist_persons_datatable = function () {
    if($(artistPersonsTableId).length > 0) {
        $(artistPersonsTableId).DataTable().destroy();
    }
    init_datatable(artistPersonsTableId, { 'unsortable_columns' : 'last', 'order' : [[ 0, "asc" ]], 'horizontal_scroll' : 'false' });
}

global.artist_events_datatable = function () {
    if($(artistEventsTableId).length > 0) {
        $(artistEventsTableId).DataTable().destroy();
    }
    init_datatable(artistEventsTableId, { 'order' : [[ 0, "desc" ]], 'horizontal_scroll' : 'false' });
}

function artists_ready() {
    if (CURRENT_CONTROLLER !== 'artists') return;
    load_artists_datatable();
    init_city_selector();

    if (!['new','create','edit','update'].includes(CURRENT_ACTION)) return;
}
$(document).on('turbolinks:load', artists_ready);

var EVENT_TASK_TYPE_OTHER = 999;

$(document).on('change', '.artist_artist_event_tasks_event_task_type select', function() {
    var task_type = $(this).find(":selected");
    if (task_type.val() == EVENT_TASK_TYPE_OTHER)
        $(this).parent().parent().parent().next('.artist_artist_event_tasks_task_type_other').removeClass('stashed');
    else
        $(this).parent().parent().parent().next('.artist_artist_event_tasks_task_type_other').addClass('stashed');
});

global.copyArtistMediaPlatforms = function (event, element) {
    // https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard

    event.preventDefault();
    var _this = $(element);
    var data = '';

    var rows = document.querySelectorAll('#artist-media-platforms-table tbody tr');

    for (var i = 0, row; row = rows[i]; i++) {
        data += row.cells[0].textContent + '\n' + row.cells[1].textContent + '\n\n';
    }

    const elem = document.createElement('textarea');
    elem.value = data;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);

    var notification = _this.data('notification');
    if (notification !== undefined)
        showNotification(notification);
}
