function load_inquiries_datatable() {
    if (CURRENT_ACTION !== 'index') return;
    init_datatable('#inquiries-datatable', { 'search_area' : '#inquiry_search', 'unsortable_columns' : [4], 'order' : [[ 0, "desc" ]], 'min_filter_length' : 3 });
}

function inquiries_ready() {
    if (CURRENT_CONTROLLER !== 'inquiries') return;

    load_inquiries_datatable();

    if (!['new','create','edit','update'].includes(CURRENT_ACTION)) return;
    init_person_selector();

    $("#inquiry-questions").sortable({
        cancel: "tr.unsortable, input",
        handle: ".sort-handle",
        start: function(event, ui) {
            ui.item.addClass("row_dragged");
        },
        stop: function(event, ui) {
            ui.item.removeClass("row_dragged");
            if ($(ui.item).nextAll('tr.nested-fields').first().hasClass("optional_date")) {
                // If a row is dragged BEFORE any of the optional_date questions, the drag must be cancelled, since those questions must always be first in the inquiry.
                $(this).sortable('cancel');
            }
        },
        update: function(event, ui) {
            renumberRows();
        }
    });

    $("#inquiry-questions tr a.remove_row").on("click", function(e){
        let currentRow = $(this).closest('tr.nested-fields');
        if (currentRow) currentRow.addClass('to_be_removed');
        renumberRows();
    });

    function renumberRows() {
        var rowNumber = 0;
        $('#inquiry-questions tr').each(function(index, row) {
            var question_type = $(row).find('select').first().val();
            var title = $(row).find('input.string').first().val();
            if (title) title = title.trim();

            if (!$(row).hasClass('to_be_removed')) {
                if (!$(row).hasClass('unsortable') || title.length > 0 || question_type.length > 0) {
                    // Let's refresh the position for each row with a value (for new rows either question type or title must have a value).
                    rowNumber += 1;
                    $(row).find('input:hidden[class="item_position"]').val(rowNumber);
                }
            }
        });
    }
}
$(document).on('turbolinks:load', inquiries_ready);

$(document).on('click', '#open_inquiry_modal', function(event) {
    event.preventDefault();
    $('#sms_message_cover_text_length').text($('.message_cover_text').val().length);
    $('#inquiry-modal').modal('show');
    update_sms_info();
});
