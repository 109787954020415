function ClientCostType(method, view, client_id, client_cost_type_id) {
    this.method = method;
    this.url = '/clients/' + client_id + '/client_cost_types/';

    if (client_cost_type_id !== undefined) {
        this.url += client_cost_type_id;
    }
    if (view === 'settings') {
        this.datatable = $('#client-cost-types-datatable').dataTable();
    }
    this.edit_modal = $('#generic-edit-modal');
}

ClientCostType.prototype.ajax_call = function(params) {
    var self = this;
    var data;
    if (params !== undefined) data = params;
    $.ajax({
        url         : self.url,
        type        : self.method,
        dataType    : 'json',
        data        : data,
        success     : function(data) {
            if (data.notice) {
                showNotification(data.notice);
                if (self.method !== 'DELETE') self.edit_modal.modal('hide');
                self.datatable.fnDraw(false);
            } else if (data.error) {
                showNotification(data.error, 'danger');
                if (self.method !== 'DELETE') $('#submit').prop('disabled',false);
            }
        },
        error       : function (request, status, error) {
            showNotification(request.responseText, 'danger', 'Error in ajax call!');
        }
    });
};

global.load_client_cost_type_edit_form = function (element, event, redirect_code, header) {
    event.preventDefault();
    var target_url = $(element).attr('href');
    if (target_url.length) {
        $('#generic-edit-modal .modal-header h3').text(header);
        $('#generic-edit-modal').modal('show');
        $.ajax({
            url         : target_url,
            data        : {
                redirect_to_view : redirect_code
            },
            success     : function(data) {
                if (data.length) {
                    $('#generic-edit-modal .modal-container').html(data);
                    $('#client_cost_type_cost_type_id').selectpicker('refresh');
                    $('#client_cost_type_product_id').selectpicker('refresh');
                }
            },
            error       : function (request, status, error) {
                var error_response = JSON.parse(request.responseText)
                showNotification(error_response.error, 'danger');
            }
        });
    }
}

window.delete_client_cost_type = function(params) {
    if (params.length == 2) {
        var client_id = params[0];
        var client_cost_type_id = params[1];
        var client_cost_type = new ClientCostType('DELETE', 'settings', client_id, client_cost_type_id);
        client_cost_type.ajax_call();
    }
}

$(document).on('submit', '.client_cost_type_form', function(event) {
    event.preventDefault();
    var _this = $(this);
    var view = _this.data('view');
    var client_id = _this.data('client-id');
    var client_cost_type_id = _this.data('id');
    var ajax_method = 'POST';
    if (client_cost_type_id !== undefined) ajax_method = 'PUT';

    var client_cost_type = new ClientCostType(ajax_method, view, client_id, client_cost_type_id);
    client_cost_type.ajax_call(_this.serialize());
});
