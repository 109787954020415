function EventTask(method, event_task_id) {
    this.method = method;
    this.url = '/event_tasks/';

    if (event_task_id !== undefined) {
        this.url += event_task_id;
    }
    this.edit_modal = $('#event-task-edit-modal');
}

EventTask.prototype.ajax_call = function(params) {
    var self = this;
    $.ajax({
        url         : self.url,
        type        : self.method,
        dataType    : 'json',
        data        : params,
        success     : function(data) {
            if (data.notice) {
                showNotification(data.notice);
                if (data.warn) showNotification(data.warn, 'warning');
                self.edit_modal.modal('hide');
            } else if (data.error) {
                showNotification(data.error, 'danger');
            }
        },
        error : function (request, status, error) {
            showNotification(request.responseText, 'danger', 'Error in ajax call!');
        },
        complete : function() {
            refreshEventTasksCalendar();
        }
    });
};

global.load_event_task_edit_form = function (event_task) {
    if (event_task.url) {
        $('#event-task-edit-modal').modal('show');
        $.ajax({
            url         : event_task.url,
            success     : function(data) {
                if (data.length) {
                    $('#event-task-edit-modal .modal-container').html(data);
                    initDatePickerElements();
                    init_person_selector();
                    init_hotel_selector();
                    $(".selectpicker").selectpicker();
                }
            }
        });
    }
}

window.create_event_task = function(params) {
    if (params.length >= 3) {
        var event_id = params[0];
        var event_task_type_id = params[1];
        var done = params[2];
        var row_element_to_remove = params[3];

        let event_task_params = {
            event_task: {
                event_id            : event_id,
                event_task_type_id  : event_task_type_id,
                done                : done
            }
        };

        $.ajax({
            url         : '/event_tasks/',
            type        : 'POST',
            dataType    : 'json',
            data        : event_task_params,
            success     : function(data) {
                if (data.notice) {
                    showNotification(data.notice);
                    if (data.warn) showNotification(data.warn, 'warning');

                    if (row_element_to_remove !== undefined) {
                        var clickedElement = document.getElementById(row_element_to_remove);
                        if (clickedElement) {
                            clickedElement.remove();
                        }
                    }
                } else if (data.error) {
                    showNotification(data.error, 'danger');
                }
            },
            error : function (request, status, error) {
                showNotification(request.responseText, 'danger', 'Error in ajax call!');
            }
        });
    } else {
        showNotification('Expected 3 parameters.', 'danger', 'Error in ajax call!');
    }
}

window.delete_event_task = function(params) {
    if (params.length == 1) {
        var event_task_id = params[0];
        var event_task = new EventTask('DELETE', event_task_id);
        event_task.ajax_call();
    }
}

$(document).on('submit', '.event_task_form', function(event) {
    event.preventDefault();
    var _this = $(this);
    var event_task_id = _this.data('id');
    var ajax_method = 'POST';
    if (event_task_id !== undefined) ajax_method = 'PUT';

    var event_task = new EventTask(ajax_method, event_task_id);
    event_task.ajax_call(_this.serialize());
});
