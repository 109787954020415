var COST_TYPE_PER_DIEM_ABROAD = 203;

function load_expenses_datatable() {
    if (CURRENT_ACTION !== 'index') return;
    init_datatable('#expenses-datatable', { 'search_area' : '#expense_search', 'order' : [[ 0, "asc" ]] });
}

function expenses_ready() {
    if (CURRENT_CONTROLLER !== 'expenses') return;
    load_expenses_datatable();

    if (!['new','create','edit','update'].includes(CURRENT_ACTION)) return;
}
$(document).on('turbolinks:load', expenses_ready);

function toggle_allocate_net_amount_checkbox() {
    var vat_amount = parseDecimalString($('#expense_vat_amount'));
    var isPersonalCost = $('#expense_personal_cost').is(":checked");

    if (vat_amount > 0 && !isPersonalCost) {
        $('.expense_allocate_net_amount').removeClass('stashed');
    } else {
        $('#expense_allocate_net_amount').prop('checked', false);
        $('.expense_allocate_net_amount').addClass('stashed');
    }
}

function toggle_vat_fields(disable) {
    if (disable) {
        $('#expense_vat_amount').attr('disabled', true);
    } else {
        $('#expense_vat_amount').attr('disabled', false);
    }
}

function calculate_expense(calculate_from_total) {
    var unit_price = parseDecimalString($('#expense_unit_price'));
    var expense_amount = parseDecimalString($('#expense_amount'));
    var expense_total_amount = parseDecimalString($('#expense_total_amount'));
    var expense_vat_amount = parseDecimalString($('#expense_vat_amount'));
    var expense_net_amount = parseDecimalString($('#expense_net_amount'));
    var vat_rate = $('#expense_vat_rate_id').find(":selected");

    if (calculate_from_total) {
        unit_price = (Math.round(100 * expense_total_amount / expense_amount) / 100)
        if (expense_vat_amount > expense_total_amount) {
            expense_vat_amount = expense_total_amount - expense_net_amount;
        }
    } else {
        expense_total_amount = expense_amount * unit_price;
    }

    $('#expense_unit_price').val(unit_price.toFixed(2));
    $('#expense_amount').val(expense_amount.toFixed(2));
    $('#expense_total_amount').val(expense_total_amount.toFixed(2));

    if (Number(vat_rate.val()) > 0) {
        var vat_percent = getVatPercent(Number(vat_rate.val()));
        expense_vat_amount = expense_total_amount - (expense_total_amount / (1 + vat_percent));
        expense_vat_amount = Math.trunc(expense_vat_amount * 100) / 100;
    }
    $('#expense_vat_amount').val(expense_vat_amount.toFixed(2));

    if (isNaN(expense_vat_amount))
        expense_net_amount = expense_total_amount;
    else
        expense_net_amount = expense_total_amount - expense_vat_amount;

    $('#expense_net_amount').val(expense_net_amount.toFixed(2));

    toggle_allocate_net_amount_checkbox();
}

$(document).on('change', '.calculate_expense', function() {
    calculate_expense(false);
});

$(document).on('change', '#expense_total_amount', function() {
    calculate_expense(true);
});

$(document).on('change', '#expense_vat_rate_id', function() {
    var vat_rate = $(this).find(":selected");
    toggle_vat_fields(vat_rate.val() > 0);
    calculate_expense(true);
});

$(document).on('change', '#expense_vat_amount', function() {
    var expense_total_amount = parseDecimalString($('#expense_total_amount'));
    var expense_vat_amount = parseDecimalString($('#expense_vat_amount'));
    var expense_net_amount = parseDecimalString($('#expense_net_amount'));

    if (expense_vat_amount > expense_total_amount) {
        expense_vat_amount = expense_total_amount - expense_net_amount;
    } else {
        expense_net_amount = expense_total_amount - expense_vat_amount;
    }

    $('#expense_vat_amount').val(expense_vat_amount.toFixed(2));
    $('#expense_net_amount').val(expense_net_amount.toFixed(2));

    toggle_allocate_net_amount_checkbox();
});

$(document).on('change', '#expense_net_amount', function() {
    var expense_amount = parseDecimalString($('#expense_amount'));
    var expense_total_amount = parseDecimalString($('#expense_total_amount'));
    var expense_vat_amount = parseDecimalString($('#expense_vat_amount'));
    var expense_net_amount = parseDecimalString($('#expense_net_amount'));
    var vat_rate = $('#expense_vat_rate_id').find(":selected");

    if (Number(vat_rate.val()) > 0) {
        var vat_percent = getVatPercent(Number(vat_rate.val()));
        expense_vat_amount = expense_net_amount * vat_percent;
        expense_total_amount = expense_net_amount + expense_vat_amount;
        var expense_unit_price = expense_total_amount / expense_amount;
        $('#expense_unit_price').val(expense_unit_price.toFixed(2));
    } else {
        if (expense_total_amount > 0) {
            if (expense_net_amount > expense_total_amount) expense_net_amount = expense_total_amount;
        } else {
            expense_total_amount = expense_net_amount;
        }
        expense_vat_amount = expense_total_amount - expense_net_amount;
    }

    $('#expense_total_amount').val(expense_total_amount.toFixed(2));
    $('#expense_vat_amount').val(expense_vat_amount.toFixed(2));
    $('#expense_net_amount').val(expense_net_amount.toFixed(2));

    toggle_allocate_net_amount_checkbox();
});

function fetch_cost_type_defaults(force_unit_price_update) {
    var cost_type_id = $('#expense_cost_type_id').find(":selected").val();
    if (Number(cost_type_id) == 0) return;

    var target_country_id = $('#expense_target_country_id').find(":selected").val();

    $.ajax({
        url         : '/cost_types/' + cost_type_id + '/cost_type_defaults.json',
        type        : 'GET',
        data        : {
            target_country_id : target_country_id
        },
        success     : function(data) {
            if (data.status == 'ok') {
                var do_calculate = false;
                if (data.default_cost !== null) {
                    var unit_price = parseDecimalString($('#expense_unit_price'));
                    if (unit_price == 0 || force_unit_price_update) {
                        $('#expense_unit_price').val(data.default_cost);
                        do_calculate = true;
                    }
                }
                if (data.vat_rate_id !== null) {
                    $('#expense_vat_rate_id').val(data.vat_rate_id);
                    toggle_vat_fields(true);
                    do_calculate = true;
                }
                if (do_calculate) {
                    calculate_expense(false);
                }
            } else {
                showNotification(data.error, 'danger');
            }
        },
        error       : function (request, status, error) {
            var error_text = request.responseText.split("\n")[0];
            if (error_text == '') error_text = error;
            showNotification(error_text, 'danger', 'Error in ajax call!');
        }
    });
}

$(document).on('change', '#expense_cost_type_id', function(){
    var cost_type_id = $(this).val();
    if (cost_type_id == COST_TYPE_PER_DIEM_ABROAD) {
        $('.expense_target_country').removeClass('stashed');
    } else {
        $('#expense_target_country_id').val(null);
        $('.expense_target_country').addClass('stashed');
    }
    fetch_cost_type_defaults(false);
});

$(document).on('change', '#expense_target_country_id', function(){
    fetch_cost_type_defaults(true);
});

$(document).on('change', '#expense_personal_cost', function() {
    toggle_allocate_net_amount_checkbox();
});