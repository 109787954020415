function CustomerAccount(method, view, person_id, customer_account_id) {
    this.method = method;
    this.url = '/people/' + person_id + '/customer_accounts/';

    if (customer_account_id !== undefined) {
        this.url += customer_account_id;
    }
    if (view === 'person') {
        this.datatable = $('#customer-accounts-datatable').dataTable();
    }
    this.edit_modal = $('#generic-edit-modal');
}

CustomerAccount.prototype.ajax_call = function(params) {
    var self = this;
    var data;
    if (params !== undefined) data = params;
    $.ajax({
        url         : self.url,
        type        : self.method,
        dataType    : 'json',
        data        : data,
        success     : function(data) {
            if (data.notice) {
                showNotification(data.notice);
                if (self.method !== 'DELETE') self.edit_modal.modal('hide');
                self.datatable.fnDraw(false);
            } else if (data.error) {
                showNotification(data.error, 'danger');
                if (self.method !== 'DELETE') $('#submit').prop('disabled',false);
            }
        },
        error : function (request, status, error) {
            showNotification(request.responseText, 'danger', 'Error in ajax call!');
        }
    });
};

global.load_customer_account_edit_form = function (element, event, redirect_code, header) {
    event.preventDefault();
    var target_url = $(element).attr('href');
    if (target_url.length) {
        $('#generic-edit-modal .modal-header h3').text(header);
        $('#generic-edit-modal').modal('show');
        $.ajax({
            url         : target_url,
            data        : {
                redirect_to_view : redirect_code
            },
            success     : function(data) {
                if (data.length) {
                    $('#generic-edit-modal .modal-container').html(data);
                    $('#customer_account_customer_account_type_id').selectpicker('refresh');
                    initDatePickerElements();
                }
            }
        });
    }
}

window.delete_customer_account = function(params) {
    if (params.length == 2) {
        var person_id = params[0];
        var customer_account_id = params[1];
        var customer_account = new CustomerAccount('DELETE', 'person', person_id, customer_account_id);
        customer_account.ajax_call();
    }
}

$(document).on('submit', '.customer_account_form', function(event) {
    event.preventDefault();
    var _this = $(this);
    var view = _this.data('view');
    var person_id = _this.data('person-id');
    var customer_account_id = _this.data('id');
    var ajax_method = 'POST';
    if (customer_account_id !== undefined) ajax_method = 'PUT';

    var customer_account = new CustomerAccount(ajax_method, view, person_id, customer_account_id);
    customer_account.ajax_call(_this.serialize());
});
