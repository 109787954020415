var customerAccountsTableId = '#customer-accounts-datatable';
var personFeesTableId = '#person-fees-datatable';
var personExpensesTableId = '#person-expenses-datatable';
var paymentAdvancesTableId = '#payment-advances-datatable';

function load_people_datatable() {
    if (CURRENT_ACTION !== 'index') return;
    init_datatable('#people-datatable', { 'search_area' : '#person_search', 'order' : [[ 0, "asc" ]], 'min_filter_length' : 3 });
}

global.customer_accounts_datatable = function () {
    if($(customerAccountsTableId).length > 0) {
        $(customerAccountsTableId).DataTable().destroy();
    }
    init_datatable(customerAccountsTableId, { 'unsortable_columns' : 'last', 'order' : [[ 0, "desc" ]], 'horizontal_scroll' : 'false' });
}

global.person_fees_datatable = function () {
    if($(personFeesTableId).length > 0) {
        $(personFeesTableId).DataTable().destroy();
    }
    init_datatable(personFeesTableId, { 'order' : [[ 0, "desc" ]], 'horizontal_scroll' : 'false' });
}

global.person_expenses_datatable = function () {
    if($(personExpensesTableId).length > 0) {
        $(personExpensesTableId).DataTable().destroy();
    }
    init_datatable(personExpensesTableId, { 'order' : [[ 5, "desc" ]], 'horizontal_scroll' : 'false' });
}

global.payment_advances_datatable = function () {
    if($(paymentAdvancesTableId).length > 0) {
        $(paymentAdvancesTableId).DataTable().destroy();
    }
    init_datatable(paymentAdvancesTableId, { 'unsortable_columns' : 'last', 'order' : [[ 0, "desc" ]], 'horizontal_scroll' : 'false' });
}

function people_ready() {
    if (CURRENT_CONTROLLER !== 'people') return;
    load_people_datatable();

    if (!['new','create','edit','update'].includes(CURRENT_ACTION)) return;
    init_company_selector();
}
$(document).on('turbolinks:load', people_ready);

// Get artist person type id's with PersonType.artist_types.map(&:id).sort
var ARTIST_PERSON_TYPE_IDS = [501, 502, 503, 504, 505, 506, 507, 508, 509];

$(document).on('change', '#person_person_type_id', function() {
    var person_type = $(this).find(":selected");
    var artist_name_length = $('#person_artist_name').val().trim().length;

    if ($.inArray(parseInt(person_type.val()), ARTIST_PERSON_TYPE_IDS) !== -1) {
        $('.person_artist_name').removeClass('stashed');
    } else {
        if (artist_name_length == 0)
            $('.person_artist_name').addClass('stashed');
    }
});

$(document).on('change', '#person_person_type_ids', function() {
    var selected_person_types = $(this).val().map(id => parseInt(id));
    var artist_name_length = $('#person_artist_name').val().trim().length;
    let artist_person_types = selected_person_types.filter((person_type) => ARTIST_PERSON_TYPE_IDS.includes(person_type));

    if (artist_person_types.length > 0) {
        $('.person_artist_name').removeClass('stashed');
    } else {
        if (artist_name_length == 0)
            $('.person_artist_name').addClass('stashed');
    }
});

$(document).on('click', '#search-person-duplicates', function(event) {
    event.preventDefault();
    $.ajax({
        url 		: '/people/' + $('#search-person-duplicates').attr('data-person-id') + '/compare_duplicates',
        type 		: 'GET',
        success 	: function(data) {
            $('#compare-duplicates-table').html(data);
        }
    });
});

$(document).on('change', '#duplicate_selector', function(event) {
    $('#compare-duplicates-table').fadeOut('fast');
    var other_person_id = $(this).val();
    $.ajax({
        url 		: '/people/' + $('#search-person-duplicates').attr('data-person-id') + '/compare_duplicates',
        type 		: 'GET',
        data        : { other_person_id : other_person_id },
        success 	: function(data) {
            $('#compare-duplicates-table').fadeIn();
            $('#compare-duplicates-table').html(data);
        }
    });
});

// This variable is needed for checking duplicates when creating new data. And because with Webpack there are no global variables, this must be defined separately for each model.
var doIgnoreDuplicatePersons = false;

$(document).on('click', '#ignore_duplicates', function(event) {
    doIgnoreDuplicatePersons = true;
    $($(this).attr('data-target-form')).trigger('submit');
});

function check_for_duplicates(event) {
    var first_name = $('#person_first_name').val();
    var last_name = $('#person_last_name').val();
    var email = $('#person_email').val();
    if (first_name.length > 0 && last_name.length > 0 && !doIgnoreDuplicatePersons) {
        event.preventDefault();
        $.ajax({
            dataType    : 'json',
            url 		: '/person_duplicate_search',
            type 		: 'GET',
            data        : {
                person : { first_name : first_name, last_name : last_name, email : email }
            },
            success 	: function(data) {
                if (data['has_duplicates']) {
                    $('#save-person').removeAttr('disabled');
                    $('.duplicates-table').html(data['duplicates_table']);
                    $('#duplicates-modal').modal('show');
                } else {
                    doIgnoreDuplicatePersons = true;
                    $(event.target).submit();
                }
            }
        });
    }
}

$(document).on('submit', 'form#new_person', function(event) {
    check_for_duplicates(event);
});
