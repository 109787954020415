function inquiry_questions_ready() {
    if (CURRENT_CONTROLLER !== 'inquiry_questions') return;

    if (!['new','create','edit','update'].includes(CURRENT_ACTION)) return;

    $("#inquiry-question-selections").sortable({
        cancel: "tr.unsortable, input",
        handle: ".sort-handle",
        start: function(event, ui) {
            ui.item.addClass("row_dragged");
        },
        stop: function(event, ui) {
            ui.item.removeClass("row_dragged");
        },
        update: function(event, ui) {
            renumberRows();
        }
    });

    $("#inquiry-question-selections tr a.remove_row").on("click", function(e){
        let currentRow = $(this).closest('tr.nested-fields');
        if (currentRow) currentRow.addClass('to_be_removed');
        renumberRows();
    });

    function renumberRows() {
        var rowNumber = 0;
        $('#inquiry-question-selections tr').each(function(index, row) {
            var title = $(row).find('input').first().val();
            if (title) title = title.trim();

            if (!$(row).hasClass('to_be_removed')) {
                if (!$(row).hasClass('unsortable') || title.length > 0) {
                    // Let's refresh the position for each row with a value (for new rows title must have a value).
                    rowNumber += 1;
                    $(row).find('input:hidden[class="item_position"]').val(rowNumber);
                }
            }
        });
    }
}
$(document).on('turbolinks:load', inquiry_questions_ready);
