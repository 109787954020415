function settlement_persons_ready() {
    if (CURRENT_CONTROLLER !== 'settlement_persons') return;

    if (!['new','create','edit','update'].includes(CURRENT_ACTION)) return;
    init_person_selector(true, true);
}
$(document).on('turbolinks:load', settlement_persons_ready);

var FEE_TYPE_PAYMENT        = 1;
var FEE_TYPE_EQUAL_SHARE    = 2;
var FEE_TYPE_FIXED_SHARE    = 3;

$(document).on('change', '#settlement_person_fee_type_id', function() {
    var fee_type = $(this).find(":selected");
    if (fee_type.val() == FEE_TYPE_PAYMENT) {
        $('.settlement_person_gross_salary').removeClass('stashed');
        $('#settlement_person_gross_salary').attr('disabled', false);
        $('.settlement_person_share_percent').addClass('stashed');
        $('#settlement_person_share_percent').attr('disabled', true);
        $('.settlement_person_total_compensation').removeClass('stashed');
    } else if (fee_type.val() == FEE_TYPE_FIXED_SHARE) {
        $('.settlement_person_gross_salary').addClass('stashed');
        $('.settlement_person_share_percent').removeClass('stashed');
        $('#settlement_person_share_percent').attr('disabled', false);
        $('.settlement_person_total_compensation').addClass('stashed');
    } else if (fee_type.val() == FEE_TYPE_EQUAL_SHARE) {
        $('.settlement_person_gross_salary').addClass('stashed');
        $('.settlement_person_share_percent').addClass('stashed');
        $('#settlement_person_share_percent').attr('disabled', true);
        $('.settlement_person_total_compensation').addClass('stashed');
    }
});

$(document).on('change', '#settlement_person_total_compensation', function() {
    if($(this).is(":checked")){
        $('#settlement_person_total_share_amount').attr('disabled', false);
        $('.settlement_person_total_share_amount').removeClass('stashed');
        $('#settlement_person_gross_salary').attr('disabled', true);
        $('.settlement_person_gross_salary').addClass('stashed');
    }
    else {
        $('#settlement_person_total_share_amount').attr('disabled', true);
        $('.settlement_person_total_share_amount').addClass('stashed');
        $('#settlement_person_gross_salary').attr('disabled', false);
        $('.settlement_person_gross_salary').removeClass('stashed');
    }
});

$(document).on('change', '#settlement_person_will_invoice', function() {
    if ($(this).is(":checked")) {

    }
    else {
        $('#settlement_person_total_share_amount').attr('disabled', false);
        $('#settlement_person_gross_salary').attr('disabled', false);
        $('#settlement_person_total_compensation').attr('disabled', false);
    }
});
