var venueEventsTableId = '#venue-events-datatable';

function load_venues_datatable() {
    if (CURRENT_ACTION !== 'index') return;
    init_datatable('#venues-datatable', { 'search_area' : '#venue_search', 'order' : [[ 0, "asc" ]], 'min_filter_length' : 3 });
}

global.venue_events_datatable = function () {
    if($(venueEventsTableId).length > 0) {
        $(venueEventsTableId).DataTable().destroy();
    }
    init_datatable(venueEventsTableId, { 'order' : [[ 0, "desc" ]], 'horizontal_scroll' : 'false' });
}

function venues_ready() {
    if (CURRENT_CONTROLLER !== 'venues') return;
    load_venues_datatable();

    if (!['new','create','edit','update'].includes(CURRENT_ACTION)) return;
    init_company_selector();
}
$(document).on('turbolinks:load', venues_ready);

var VENUE_TYPE_OTHER    = -1;

$(document).on('change', '#venue_venue_type_id', function() {
    var venue_type = $(this).find(":selected");
    if (venue_type.val() == VENUE_TYPE_OTHER) {
        $('.venue_venue_type_other').removeClass('stashed');
    } else {
        $('.venue_venue_type_other').addClass('stashed');
    }
});

$(document).on('click', '#search-venue-duplicates', function(event) {
    event.preventDefault();
    $.ajax({
        url 		: '/venues/' + $('#search-venue-duplicates').attr('data-venue-id') + '/compare_duplicates',
        type 		: 'GET',
        success 	: function(data) {
            $('#compare-duplicates-table').html(data);
        }
    });
});

$(document).on('change', '#duplicate_selector', function(event) {
    $('#compare-duplicates-table').fadeOut('fast');
    var other_venue_id = $(this).val();
    $.ajax({
        url 		: '/venues/' + $('#search-venue-duplicates').attr('data-venue-id') + '/compare_duplicates',
        type 		: 'GET',
        data        : { other_venue_id : other_venue_id },
        success 	: function(data) {
            $('#compare-duplicates-table').fadeIn();
            $('#compare-duplicates-table').html(data);
        }
    });
});

// This variable is needed for checking duplicates when creating new data. And because with Webpack there are no global variables, this must be defined separately for each model.
var doIgnoreDuplicateVenues = false;

$(document).on('click', '#ignore_duplicates', function(event) {
    doIgnoreDuplicateVenues = true;
    $($(this).attr('data-target-form')).trigger('submit');
});

function check_for_duplicates(event) {
    var name = $('#venue_name').val();
    var city = $('#venue_city').val();
    var country_id = $('#venue_country_id').val();
    if (name.length > 0 && !doIgnoreDuplicateVenues) {
        event.preventDefault();
        $.ajax({
            dataType    : 'json',
            url 		: '/venue_duplicate_search',
            type 		: 'GET',
            data        : {
                venue : { name : name, city : city, country_id : country_id }
            },
            success 	: function(data) {
                if (data['has_duplicates']) {
                    $('#save-venue').removeAttr('disabled');
                    $('.duplicates-table').html(data['duplicates_table']);
                    $('#duplicates-modal').modal('show');
                } else {
                    doIgnoreDuplicateVenues = true;
                    $(event.target).submit();
                }
            }
        });
    }
}

$(document).on('submit', 'form#new_venue', function(event) {
    check_for_duplicates(event);
});
