var clientCostTypesTableId = '#client-cost-types-datatable';
var productsTableId = '#products-datatable';

global.client_cost_types_datatable = function () {
    if($(clientCostTypesTableId).length > 0) {
        $(clientCostTypesTableId).DataTable().destroy();
    }
    init_datatable(clientCostTypesTableId, { 'unsortable_columns' : 'last', 'order' : [[ 0, "asc" ]], 'horizontal_scroll' : 'false' });
}

global.products_datatable = function () {
    if($(productsTableId).length > 0) {
        $(productsTableId).DataTable().destroy();
    }
    init_datatable(productsTableId, { 'unsortable_columns' : 'last', 'order' : [[ 0, "asc" ]], 'horizontal_scroll' : 'false' });
}

function settings_ready() {
    if (CURRENT_CONTROLLER !== 'settings') return;

    if (!['index'].includes(CURRENT_ACTION)) return;

    client_cost_types_datatable();
}
$(document).on('turbolinks:load', settings_ready);
