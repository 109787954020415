var eventPersonsTableId = '#event-persons-datatable';
var eventGuestsTableId  = '#event-guests-datatable';

function load_events_datatable() {
    if (CURRENT_ACTION !== 'index') return;
    init_datatable('#events-datatable', { 'search_area' : '#event_search', 'order' : [[ 0, "asc" ]] });
}

global.event_persons_datatable = function () {
    if($(eventPersonsTableId).length > 0) {
        $(eventPersonsTableId).DataTable().destroy();
    }
    init_datatable(eventPersonsTableId, { 'unsortable_columns' : 'last', 'order' : [[ 0, "asc" ]], 'horizontal_scroll' : 'false' });
}

global.event_guests_datatable = function () {
    if($(eventGuestsTableId).length > 0) {
        $(eventGuestsTableId).DataTable().destroy();
    }
    init_datatable(eventGuestsTableId, { 'unsortable_columns' : 'last', 'order' : [[ 0, "asc" ]], 'horizontal_scroll' : 'false' });
}

function events_ready() {
    if (CURRENT_CONTROLLER !== 'events') return;
    load_events_datatable();

    if (!['new','create','edit','update'].includes(CURRENT_ACTION)) return;
    init_venue_selector();
    init_city_selector();
    init_customer_selector();
    init_agreement_person_selector();
    init_contact_person_selector();
    init_person_selector();
    init_company_selector();
    init_hotel_selector();
}
$(document).on('turbolinks:load', events_ready);

$(document).on('click', '#open_production_info_modal', function(event) {
    event.preventDefault();
    $('#sms_message_cover_text_length').text($('.message_cover_text').val().length);
    $('#send-production-info-modal').modal('show');
    update_sms_info();
});

// Event constants

var EVENT_STATE_DRAFT       = 1;
var EVENT_STATE_INQUIRY     = 2;

var TICKET_BONUS_TYPE_PERCENTAGE    = 1;
var TICKET_BONUS_TYPE_AMOUNT        = 2;
var TICKET_BONUS_TYPE_FIXED         = 3;

var TICKET_TYPE_OTHER = 999;

var COMMISSION_TYPE_PERCENT             = 1;
var COMMISSION_TYPE_FIXED               = 2;
var COMMISSION_TYPE_PERCENT_AFTER_COSTS = 3;

var PERSON_TYPE_CONTACT_PERSON_OTHER = 991;
var EVENT_SCHEDULE_TYPE_OTHER = 999;
var EVENT_TASK_TYPE_OTHER = 999;

var EVENT_LINK_TYPE_OTHER = 999;

$(document).on('change', '.event_event_state select', function() {
    var event_state = $(this).find(":selected");
    if ([EVENT_STATE_DRAFT, EVENT_STATE_INQUIRY].includes(parseInt(event_state.val())))
        $('.event_optional_dates').removeClass('stashed');
    else
        $('.event_optional_dates').addClass('stashed');
});

function calculate_commission() {
    var agreement_amount = parseDecimalString($('#event_agreement_amount'));
    var guaranteed_amount = parseDecimalString($('#event_guaranteed_amount'));
    var bonus_amount = parseDecimalString($('#event_bonus_amount'));
    var total_amount = parseDecimalString($('#event_total_amount'));
    var vat_amount = parseDecimalString($('#event_vat_amount'));
    var total_amount_with_vat = parseDecimalString($('#event_total_amount_with_vat'));

    var amount_defined_with_vat = $('#event_amount_defined_with_vat').is(":checked");

    var vat_rate = $('#event_vat_rate_id').find(":selected");
    var vat_percent = 0;
    if (Number(vat_rate.val()) >= 0) {
        vat_percent = getVatPercent(Number(vat_rate.val()));
    }

    if (vat_percent > 0 && amount_defined_with_vat) {
        // if event has VAT and fee has been agreed as 'VAT included', amounts must be calculated from 'top to bottom'
        vat_amount = total_amount_with_vat - (total_amount_with_vat / (1 + vat_percent));
        total_amount = total_amount_with_vat - vat_amount;
        agreement_amount = total_amount - bonus_amount;
    } else {
        if (guaranteed_amount > agreement_amount)
            total_amount = guaranteed_amount;
        else
            total_amount = agreement_amount;

        if (bonus_amount > 0) {
            if (bonus_amount > guaranteed_amount)
                // if there is a (ticket) bonus amount, which is greater than guaranteed amount, add the bonus to agreement amount
                total_amount = agreement_amount + bonus_amount;
            else
                // otherwise add the guaranteed amount to agreement amount
                total_amount = agreement_amount + guaranteed_amount;
        }

        total_amount_with_vat = total_amount;
        if (vat_percent > 0) {
            vat_amount = total_amount * vat_percent;
            total_amount_with_vat += vat_amount;
        }
    }

    $('#event_agreement_amount').val(agreement_amount.toFixed(2));
    $('#event_total_amount').val(total_amount.toFixed(2));
    $('#event_vat_amount').val(vat_amount.toFixed(2));
    $('#event_total_amount_with_vat').val(total_amount_with_vat.toFixed(2));

    var event_commission_amount = parseDecimalString($('#event_commission_amount'));
    var event_commission_amount_agent = parseDecimalString($('#event_commission_amount_agent'));
    var event_commission_percent = parseDecimalString($('#event_commission_percent'));
    var event_commission_percent_agent = parseDecimalString($('#event_commission_percent_agent'));

    if (event_commission_percent_agent > event_commission_percent) { // agent's commission percent can't be bigger than commission percent
        event_commission_percent_agent = event_commission_percent;
        $('#event_commission_percent_agent').val(event_commission_percent_agent.toFixed(2));
    }

    var commission_type = $('#event_commission_type_id').find(":selected");
    if (commission_type.val() == COMMISSION_TYPE_FIXED) {
        if (event_commission_amount > total_amount) { // commission amount can't be bigger than max amount
            event_commission_amount = total_amount;
            $('#event_commission_amount').val(event_commission_amount.toFixed(2));
        }
        if (event_commission_amount_agent > event_commission_amount) { // agent's amount can't be bigger than commission amount
            event_commission_amount_agent = event_commission_amount;
            $('#event_commission_amount_agent').val(event_commission_amount_agent.toFixed(2));
        }
    } else {
        var commission_basis_amount = total_amount;
        if (commission_type.val() == COMMISSION_TYPE_PERCENT_AFTER_COSTS) {
            var total_costs = parseDecimalString($('#event_total_costs'));
            commission_basis_amount = commission_basis_amount - total_costs;
            if (commission_basis_amount < 0) commission_basis_amount = 0;
        }
        var commission = (event_commission_percent/100) * commission_basis_amount;
        var commission_agent = (event_commission_percent_agent/100) * commission_basis_amount;
        $('#event_commission_amount').val(commission.toFixed(2));
        $('#event_commission_amount_agent').val(commission_agent.toFixed(2));
    }
}

$(document).on('change', '#event_commission_type_id', function() {
    var commission_type = $(this).find(":selected");
    if (commission_type.val() == COMMISSION_TYPE_FIXED) {
        $('#event_commission_amount').attr('disabled', false);
        $('#event_commission_amount_agent').attr('disabled', false);
        $('.event_commission_percent').addClass('stashed');
        $('.event_commission_percent_agent').addClass('stashed');
    } else {
        $('#event_commission_amount').attr('disabled', true);
        $('#event_commission_amount_agent').attr('disabled', true);
        $('.event_commission_percent').removeClass('stashed');
        $('.event_commission_percent_agent').removeClass('stashed');
    }
    calculate_commission();
});

$(document).on('change', '#event_vat_rate_id', function() {
    var vat_rate = $(this).find(":selected");
    var vat_percent = getVatPercent(Number(vat_rate.val()));
    if (vat_percent > 0) {
        $('.event_vat_amount').removeClass('stashed');
        $('.event_total_amount_with_vat').removeClass('stashed');
        $('.event_amount_defined_with_vat').removeClass('stashed');
    } else {
        $('.event_vat_amount').addClass('stashed');
        $('#event_agreement_amount').attr('disabled', false);
        $('#event_amount_defined_with_vat').prop('checked', false);
        $('.event_total_amount_with_vat').addClass('stashed');
        $('.event_amount_defined_with_vat').addClass('stashed');
    }
    calculate_commission();
});

$(document).on('change', '#event_amount_defined_with_vat', function() {
    if($(this).is(":checked")){
        $('#event_agreement_amount').attr('disabled', true);
        $('#event_total_amount_with_vat').attr('disabled', false);
    }
    else {
        $('#event_agreement_amount').attr('disabled', false);
        $('#event_total_amount_with_vat').attr('disabled', true);
    }
    calculate_commission();
});

$(document).on('change', '#event_special_fee', function() {
    if($(this).is(":checked")){
        $('.event_special_fee_text').removeClass('stashed');
    }
    else {
        $('.event_special_fee_text').addClass('stashed');
    }
});

$(document).on('change', '.calculate_commission', function() {
    calculate_commission();
});

$(document).on('change', '.event_event_contacts_person_type select', function() {
    var person_type = $(this).find(":selected");
    if (person_type.val() == PERSON_TYPE_CONTACT_PERSON_OTHER)
        $(this).parent().parent().next('.event_event_contacts_person_type_other').removeClass('stashed');
    else
        $(this).parent().parent().next('.event_event_contacts_person_type_other').addClass('stashed');
});

$(document).on('change', '.event_event_contacts_new_contact input[type=checkbox]', function() {
    var first_name_field = $(this).parents('.nested-fields').find('td .event_event_contacts_first_name');
    var last_name_field  = $(this).parents('.nested-fields').find('td .event_event_contacts_last_name');
    var email_field      = $(this).parents('.nested-fields').find('td .event_event_contacts_email');
    var phone_field      = $(this).parents('.nested-fields').find('td .event_event_contacts_phone');

    if($(this).is(":checked")){
        first_name_field.removeClass('stashed');
        last_name_field.removeClass('stashed');
        email_field.removeClass('stashed');
        phone_field.removeClass('stashed');
    }
    else {
        first_name_field.addClass('stashed');
        last_name_field.addClass('stashed');
        email_field.addClass('stashed');
        phone_field.addClass('stashed');
    }
});

$(document).on('change', '.event_event_schedules_event_schedule_type select', function() {
    var schedule_type = $(this).find(":selected");
    if (schedule_type.val() == EVENT_SCHEDULE_TYPE_OTHER)
        $(this).parent().parent().next('.event_event_schedules_schedule_type_other').removeClass('stashed');
    else
        $(this).parent().parent().next('.event_event_schedules_schedule_type_other').addClass('stashed');
});

$(document).on('change', '.event_event_tickets_ticket_type select', function() {
    var ticket_type = $(this).find(":selected");
    if (ticket_type.val() == TICKET_TYPE_OTHER)
        $(this).parent().parent().next('.event_event_tickets_ticket_type_other').removeClass('stashed');
    else
        $(this).parent().parent().next('.event_event_tickets_ticket_type_other').addClass('stashed');
});

$(document).on('change', '.event_event_tickets_ticket_bonus_type select', function() {
    var ticket_bonus_type = $(this).find(":selected");
    if (ticket_bonus_type.val() == TICKET_BONUS_TYPE_PERCENTAGE) {
        $(this).parent().parent().next('.event_event_tickets_bonus_percent_per_ticket').removeClass('stashed');
        $(this).parent().parent().next().next('.event_event_tickets_bonus_amount_per_ticket').addClass('stashed');
        $(this).parent().parent().next().next().next('.event_event_tickets_bonus_amount_fixed').addClass('stashed');
        $(this).parent().parent().next().next().next().next('.event_event_tickets_trigger_point').removeClass('stashed');
    } else if (ticket_bonus_type.val() == TICKET_BONUS_TYPE_AMOUNT) {
        $(this).parent().parent().next('.event_event_tickets_bonus_percent_per_ticket').addClass('stashed');
        $(this).parent().parent().next().next('.event_event_tickets_bonus_amount_per_ticket').removeClass('stashed');
        $(this).parent().parent().next().next().next('.event_event_tickets_bonus_amount_fixed').addClass('stashed');
        $(this).parent().parent().next().next().next().next('.event_event_tickets_trigger_point').removeClass('stashed');
    } else if (ticket_bonus_type.val() == TICKET_BONUS_TYPE_FIXED) {
        $(this).parent().parent().next('.event_event_tickets_bonus_percent_per_ticket').addClass('stashed');
        $(this).parent().parent().next().next('.event_event_tickets_bonus_amount_per_ticket').addClass('stashed');
        $(this).parent().parent().next().next().next('.event_event_tickets_bonus_amount_fixed').removeClass('stashed');
        $(this).parent().parent().next().next().next().next('.event_event_tickets_trigger_point').removeClass('stashed');
    } else {
        $(this).parent().parent().next('.event_event_tickets_bonus_percent_per_ticket').addClass('stashed');
        $(this).parent().parent().next().next('.event_event_tickets_bonus_amount_per_ticket').addClass('stashed');
        $(this).parent().parent().next().next().next('.event_event_tickets_bonus_amount_fixed').addClass('stashed');
        $(this).parent().parent().next().next().next().next('.event_event_tickets_trigger_point').addClass('stashed');
    }
});

$(document).on('change', '.event_event_tasks_event_task_type select', function() {
    var task_type = $(this).find(":selected");
    if (task_type.val() == EVENT_TASK_TYPE_OTHER)
        $(this).parent().parent().parent().next('.event_event_tasks_task_type_other').removeClass('stashed');
    else
        $(this).parent().parent().parent().next('.event_event_tasks_task_type_other').addClass('stashed');
});

$(document).on('change', '.event_event_tasks_done input[type=checkbox]', function() {
    var deadline_at_field = $(this).parents('.nested-fields').find('td .event_event_tasks_deadline_at');
    var done_at_field = $(this).parents('.nested-fields').find('td .event_event_tasks_done_at');

    if($(this).is(":checked")){
        deadline_at_field.addClass('stashed');
        done_at_field.removeClass('stashed');
    }
    else {
        deadline_at_field.removeClass('stashed');
        deadline_at_field.find('input.date_time_picker').attr('disabled', false);
        deadline_at_field.find('input.time_picker').attr('disabled', false);
        done_at_field.addClass('stashed');
    }
});

$(document).on('change', '.event_event_links_event_link_type select', function() {
    var link_type = $(this).find(":selected");
    if (link_type.val() == EVENT_LINK_TYPE_OTHER)
        $(this).parent().parent().parent().next('.event_event_links_event_link_type_other').removeClass('stashed');
    else
        $(this).parent().parent().parent().next('.event_event_links_event_link_type_other').addClass('stashed');
});
