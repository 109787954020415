function load_hotels_datatable() {
    if (CURRENT_ACTION !== 'index') return;
    init_datatable('#hotels-datatable', { 'search_area' : '#hotel_search', 'order' : [[ 0, "asc" ]], 'min_filter_length' : 3 });
}

function hotels_ready() {
    if (CURRENT_CONTROLLER !== 'hotels') return;
    load_hotels_datatable();
}
$(document).on('turbolinks:load', hotels_ready);

$(document).on('click', '#search-hotel-duplicates', function(event) {
    event.preventDefault();
    $.ajax({
        url 		: '/hotels/' + $('#search-hotel-duplicates').attr('data-hotel-id') + '/compare_duplicates',
        type 		: 'GET',
        success 	: function(data) {
            $('#compare-duplicates-table').html(data);
        }
    });
});

$(document).on('change', '#duplicate_selector', function(event) {
    $('#compare-duplicates-table').fadeOut('fast');
    var other_hotel_id = $(this).val();
    $.ajax({
        url 		: '/hotels/' + $('#search-hotel-duplicates').attr('data-hotel-id') + '/compare_duplicates',
        type 		: 'GET',
        data        : { other_hotel_id : other_hotel_id },
        success 	: function(data) {
            $('#compare-duplicates-table').fadeIn();
            $('#compare-duplicates-table').html(data);
        }
    });
});

// This variable is needed for checking duplicates when creating new data. And because with Webpack there are no global variables, this must be defined separately for each model.
var doIgnoreDuplicateHotels = false;

$(document).on('click', '#ignore_duplicates', function(event) {
    doIgnoreDuplicateHotels = true;
    $($(this).attr('data-target-form')).trigger('submit');
});

function check_for_duplicates(event) {
    var name = $('#hotel_name').val();
    var city = $('#hotel_city').val();
    var country_id = $('#hotel_country_id').val();
    if (name.length > 0 && !doIgnoreDuplicateHotels) {
        event.preventDefault();
        $.ajax({
            dataType    : 'json',
            url 		: '/hotel_duplicate_search',
            type 		: 'GET',
            data        : {
                hotel : { name : name, city : city, country_id : country_id }
            },
            success 	: function(data) {
                if (data['has_duplicates']) {
                    $('#save-hotel').removeAttr('disabled');
                    $('.duplicates-table').html(data['duplicates_table']);
                    $('#duplicates-modal').modal('show');
                } else {
                    doIgnoreDuplicateHotels = true;
                    $(event.target).submit();
                }
            }
        });
    }
}

$(document).on('submit', 'form#new_hotel', function(event) {
    check_for_duplicates(event);
});
