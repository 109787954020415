function person_roles_ready() {
    if (CURRENT_CONTROLLER !== 'person_roles') return;

    if (!['new','create','edit','update'].includes(CURRENT_ACTION)) return;
}
$(document).on('turbolinks:load', person_roles_ready);

var ROLE_ADMIN      = 1;
var ROLE_FINANCIALS = 8;

$(document).on('change', '.person_role_role select', function() {
    var role = $(this).find(":selected");
    if ([ROLE_ADMIN, ROLE_FINANCIALS].includes(parseInt(role.val()))) {
        $('.person_role_settlement_type_ids').removeClass('stashed');
    } else {
        $('.person_role_settlement_type_ids').addClass('stashed');
    }
});
