function load_settlements_datatable() {
    if (CURRENT_ACTION !== 'index') return;
    init_datatable('#settlements-datatable', { 'search_area' : '#settlement_search', 'order' : [[ 3, "asc" ]] });
}

function settlements_ready() {
    if (CURRENT_CONTROLLER !== 'settlements') return;
    load_settlements_datatable();
}
$(document).on('turbolinks:load', settlements_ready);

var settlementPersonsTableId = '#settlement-persons-datatable';

global.settlement_persons_datatable = function () {
    if($(settlementPersonsTableId).length > 0) {
        $(settlementPersonsTableId).DataTable().destroy();
    }
    init_datatable(settlementPersonsTableId, { 'unsortable_columns' : 'last', 'order' : [[ 0, "asc" ]], 'horizontal_scroll' : 'false' });
}

var SETTLEMENT_TYPE_EVENT   = 1;
var SETTLEMENT_TYPE_PAYMENT = 2;

$(document).on('change', '#settlement_settlement_type_id', function() {
    var settlement_type = $(this).find(":selected");

    if ([SETTLEMENT_TYPE_EVENT, SETTLEMENT_TYPE_PAYMENT].includes(parseInt(settlement_type.val())))
        $('.settlement_artist').removeClass('stashed');
    else
        $('.settlement_artist').addClass('stashed');
});