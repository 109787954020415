class removeFields {
    // This executes when the function is instantiated.
    constructor() {
        this.iterateLinks()
    }

    iterateLinks() {
        // Use event delegation to ensure any fields added after the page loads are captured.
        document.addEventListener('click', e => {
            if (e.target) {
                if (e.target.classList.contains("remove_row")) {
                    this.handleClick(e.target, e, 'delete')
                } else if (e.target.classList.contains("detach_row")) {
                    this.handleClick(e.target, e, 'detach')
                }
            }
        })
    }

    handleClick(link, e, action) {
        // Stop the function from executing if a link or event were not passed into the function.
        if (!link || !e) return
        // Prevent the browser from following the URL.
        e.preventDefault()
        // Find the parent wrapper for the set of nested fields.
        let fieldParent = link.closest('.nested-fields')
        // If there is a parent wrapper, find the hidden field.

        if (action == 'delete') {
            let hiddenField = fieldParent ? fieldParent.querySelector('input[type="hidden"][value="false"]') : null
            // If there is a hidden field, update the value to `1` and hide the corresponding nested fields.
            if (hiddenField) {
                hiddenField.value = 1
                fieldParent.style.display = 'none'
            }
        } else {
            let hiddenField = fieldParent ? fieldParent.querySelector('input[type="hidden"]') : null
            // If there is a hidden field, clear the value and hide the corresponding nested fields.
            if (hiddenField) {
                hiddenField.value = null
                fieldParent.style.display = 'none'
            }
        }
    }
}

// Wait for turbolinks to load, otherwise `document.querySelectorAll()` won't work
window.addEventListener('turbolinks:load', () => new removeFields());