// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import 'bootstrap'

import 'bootstrap-datepicker';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.fi';
import 'bootstrap-datepicker/js/locales/bootstrap-datepicker.sv';
import 'bootstrap-timepicker';
import './datepicker.js'
import 'bootstrap-select';
import 'bootstrap-notify';

import 'bootbox'
global.bootbox = require('bootbox');

import '../stylesheets/application'
import "startbootstrap-sb-admin-2/css/sb-admin-2.css";
import 'startbootstrap-sb-admin-2/js/sb-admin-2'

import 'datatables.net';
import 'datatables.net-bs4';

import 'typeahead.js';
import 'jquery-ui-sortable';

import './nested-forms/remove_fields'

import './bootstrap.js'
import './helpers.js'
import './artist_persons.js'
import './artists.js'
import './clients.js'
import './client_cost_types.js'
import './companies.js'
import './datatables.js'
import './event_guests.js'
import './event_persons.js'
import './event_tasks.js'
import './events.js'
import './expenses.js'
import './fullcalendar.js'
import './hotels.js'
import './inquiries.js'
import './inquiry_questions.js'
import './customer_accounts.js'
import './payment_advances.js'
import './people.js'
import './person_roles.js'
import './invoices.js'
import './purchase_invoices.js'
import './settings'
import './settlement_persons.js'
import './settlements.js'
import './tours.js'
import './users.js'
import './venues.js'

// Include jQuery in global and window scope (so you can access it globally)
// In your web browser, when you type $('.div'), it is actually referring to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("trix")
require("@rails/actiontext")