function clients_ready() {
    if (CURRENT_CONTROLLER == 'client_users' && CURRENT_ACTION == 'index') {
        init_datatable('#client-users-datatable', { 'search_area' : '#person_search', 'unsortable_columns' : [2], 'order' : [[ 0, "asc" ]], 'min_filter_length' : 3 });
    }

    if (CURRENT_CONTROLLER == 'client_trade_names' && ['new','create','edit','update'].includes(CURRENT_ACTION)) {
        init_company_selector();
    }
}
$(document).on('turbolinks:load', clients_ready);
