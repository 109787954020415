function load_purchase_invoices_datatable() {
    if (CURRENT_ACTION !== 'index') return;
    init_datatable('#purchase-invoices-datatable', { 'search_area' : '#purchase_invoice_search', 'order' : [[ 0, "asc" ]] });
}

function purchase_invoices_ready() {
    if (CURRENT_CONTROLLER !== 'purchase_invoices') return;
    load_purchase_invoices_datatable();

    if (!['new','create','edit','update'].includes(CURRENT_ACTION)) return;
    init_company_selector();
}
$(document).on('turbolinks:load', purchase_invoices_ready);

function toggle_allocate_net_amount_checkbox(vat_amount) {
    if (vat_amount > 0) {
        $('.purchase_invoice_allocate_net_amount').removeClass('stashed');
    } else {
        $('#purchase_invoice_allocate_net_amount').prop('checked', false);
        $('.purchase_invoice_allocate_net_amount').addClass('stashed');
    }
}

function calculate_amounts(element) {
    var purchase_invoice_total_amount = parseDecimalString($('#purchase_invoice_total_amount'));
    var purchase_invoice_net_amount = parseDecimalString($('#purchase_invoice_net_amount'));
    var purchase_invoice_vat_amount = parseDecimalString($('#purchase_invoice_vat_amount'));

    var vat_percent;
    var vat_rate = $('#purchase_invoice_vat_rate_id').find(":selected");
    if (Number(vat_rate.val()) >= 0) {
        vat_percent = getVatPercent(Number(vat_rate.val()));
    }

    switch(element.attr("id")) {
        case 'purchase_invoice_cost_type_id':
            if (vat_percent !== undefined) {
                purchase_invoice_vat_amount = purchase_invoice_total_amount - (purchase_invoice_total_amount / (1 + vat_percent));
            }
            purchase_invoice_net_amount = purchase_invoice_total_amount - purchase_invoice_vat_amount;
            break;
        case 'purchase_invoice_total_amount':
            if (vat_percent !== undefined) {
                purchase_invoice_vat_amount = purchase_invoice_total_amount - (purchase_invoice_total_amount / (1 + vat_percent));
            }
            purchase_invoice_net_amount = purchase_invoice_total_amount - purchase_invoice_vat_amount;
            if (purchase_invoice_total_amount > 0 && purchase_invoice_net_amount < 0) {
                purchase_invoice_net_amount = purchase_invoice_total_amount;
                purchase_invoice_vat_amount = 0;
            }
            break;
        case 'purchase_invoice_vat_rate_id':
            if (vat_percent !== undefined) {
                purchase_invoice_vat_amount = purchase_invoice_total_amount - (purchase_invoice_total_amount / (1 + vat_percent));
            }
            purchase_invoice_net_amount = purchase_invoice_total_amount - purchase_invoice_vat_amount;
            break;
        case 'purchase_invoice_net_amount':
            if (vat_percent !== undefined) {
                purchase_invoice_vat_amount = vat_percent * purchase_invoice_net_amount;
                purchase_invoice_total_amount = purchase_invoice_net_amount + purchase_invoice_vat_amount;
            } else {
                if (purchase_invoice_net_amount > purchase_invoice_total_amount) {
                    purchase_invoice_net_amount = purchase_invoice_total_amount;
                    purchase_invoice_vat_amount = 0;
                } else {
                    purchase_invoice_vat_amount = purchase_invoice_total_amount - purchase_invoice_net_amount;
                }
            }
            break;
        case 'purchase_invoice_vat_amount':
            purchase_invoice_net_amount = purchase_invoice_total_amount - purchase_invoice_vat_amount;
            if (purchase_invoice_vat_amount > 0 && purchase_invoice_net_amount < 0) {
                purchase_invoice_net_amount = purchase_invoice_total_amount;
                purchase_invoice_vat_amount = 0;
            }
            break;
        default:
    }
    $('#purchase_invoice_total_amount').val(purchase_invoice_total_amount.toFixed(2));
    $('#purchase_invoice_net_amount').val(purchase_invoice_net_amount.toFixed(2));
    $('#purchase_invoice_vat_amount').val(purchase_invoice_vat_amount.toFixed(2));

    toggle_allocate_net_amount_checkbox(purchase_invoice_vat_amount);
}

$(document).on('change', '.calculate_purchase_invoice_amounts', function() {
    calculate_amounts($(this));
});

function fetch_cost_type_defaults(element, force_amount_update) {
    var cost_type_id = $('#purchase_invoice_cost_type_id').find(":selected").val();
    if (Number(cost_type_id) == 0) return;

    $.ajax({
        url         : '/cost_types/' + cost_type_id + '/cost_type_defaults.json',
        type        : 'GET',
        success     : function(data) {
            if (data.status == 'ok') {
                var do_calculate = false;
                if (data.default_cost !== null) {
                    var total_amount = parseDecimalString($('#purchase_invoice_total_amount'));
                    if (total_amount == 0 || force_amount_update) {
                        $('#purchase_invoice_total_amount').val(data.default_cost);
                        do_calculate = true;
                    }
                }
                if (data.vat_rate_id !== null) {
                    $('#purchase_invoice_vat_rate_id').val(data.vat_rate_id);
                    toggle_vat_fields(true);
                    do_calculate = true;
                }
                if (do_calculate) {
                    calculate_amounts(element);
                }
            } else {
                showNotification(data.error, 'danger');
            }
        },
        error       : function (request, status, error) {
            var error_text = request.responseText.split("\n")[0];
            if (error_text == '') error_text = error;
            showNotification(error_text, 'danger', 'Error in ajax call!');
        }
    });
}

$(document).on('change', '#purchase_invoice_cost_type_id', function(){
    fetch_cost_type_defaults($(this), false);
});

function toggle_vat_fields(disable) {
    if (disable) {
        $('#purchase_invoice_vat_amount').attr('disabled', true);
    } else {
        $('#purchase_invoice_vat_amount').attr('disabled', false);
    }
}

$(document).on('change', '#purchase_invoice_vat_rate_id', function() {
    var vat_rate = $(this).find(":selected");
    toggle_vat_fields(vat_rate.val() > 0);
    calculate_amounts($(this));
});