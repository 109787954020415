// Event handlers and helpers for different Bootstrap elements

function save_search_criteria(search_form_name) {
    var search_form = '#' + search_form_name;
    var search_criteria = new Array;

    $(search_form + " input[type=checkbox]").each(function() {
        if($(this).attr('id') !== undefined && (!$(this).hasClass('must_be_checked') || $(this).is(':checked'))) {
            search_criteria.push({ id: $(this).attr('id'), value: $(this).attr('value') });
        }
    });
    $(search_form + " input[type=radio]").each(function() {
        if($(this).attr('id') !== undefined && (!$(this).hasClass('must_be_checked') || $(this).is(':checked'))) {
            search_criteria.push({ id: $(this).attr('id'), value: $(this).attr('value') });
        }
    });
    $(search_form + " select").each(function() {
        if($(this).attr('id') !== undefined) {
            search_criteria.push({ id: $(this).attr('id'), value: $(this).val() });
        }
    });
    $(search_form + " input").each(function() {
        if($(this).attr('id') !== undefined) {
            // Let's push input name and value only if input's type is not radio (or if it is radio, it has to be checked)
            if($(this).attr('type') != 'radio' || ($(this).attr('type') == 'radio' && $(this).is(':checked'))) {
                search_criteria.push({ id: $(this).attr('id'), value: $(this).val() });
            }
        }
    });
    localStorage.setItem(search_form, JSON.stringify(search_criteria));
}

function load_search_criteria(search_form_name) {
    var search_form = '#' + search_form_name;
    var data = localStorage.getItem(search_form);
    if (data != null) {
        var search_criteria = JSON.parse(data);
        if (search_criteria != null) {
            search_criteria.forEach(function(item, index) {
                var element_id = '#' + item.id;
                $(element_id).val(item.value);
                if ($(element_id).hasClass('selectpicker'))
                    $(element_id).selectpicker('refresh');
            });
        }
    }
}

$(document).on('turbolinks:load', function() {
    if($('.search-form').length != 0) {
        $('.search-form').each(function() {
            load_search_criteria($(this).attr('id'));
        });
    }
});

$(document).on('turbolinks:before-cache', function() {
    if($('.search-form').length != 0) {
        $('.search-form').each(function() {
            save_search_criteria($(this).attr('id'));
        });

        $('.search-form .selectpicker').each(function() {
            var picker = $(this).data('selectpicker');
            if (picker) {
                picker.destroy();
                $(this).addClass('selectpicker');
            }
        });
    }
});
