global.load_create_user_form = function (element, event, person_id) {
    event.preventDefault();
    var target_url = $(element).attr('href');
    if (target_url.length) {
        $('#create-user-modal').modal('show');
        $.ajax({
            url         : target_url,
            data        : {
                person_id : person_id
            },
            success     : function(data) {
                if (data.length) {
                    $('#create-user-modal .modal-container').html(data);
                    $('#user_role_id').selectpicker('refresh');

                    $('#create-user-modal .modal-container').find('input.required, select.required').on('change', function() {
                        var _this = $(this);
                        var value = _this.val();
                        if (value == null) {
                            value = '';
                        } else {
                            value = value.trim();
                        }
                        if (value.length > 0) {
                            if (_this.is('select')) {
                                _this.parent().addClass('is-valid');
                                _this.parent().removeClass('is-invalid');
                            } else {
                                _this.closest('.form-control').addClass('is-valid');
                                _this.closest('.form-control').removeClass('is-invalid');
                            }
                            $('#add-new-user').prop('disabled', false);
                        }
                    });
                }
            }
        });
    }
}

global.load_connect_user_form = function (element, event, user_id) {
    event.preventDefault();
    var target_url = $(element).attr('href');
    if (target_url.length) {
        $('#connect-user-modal').modal('show');
        $.ajax({
            url         : target_url,
            data        : {
                user_id : user_id
            },
            success     : function(data) {
                if (data.length) {
                    $('#connect-user-modal .modal-container').html(data);
                    $('#person_role_id').selectpicker('refresh');

                    $('#connect-user-modal .modal-container').find('input.required, select.required').on('change', function() {
                        var _this = $(this);
                        var value = _this.val();
                        if (value == null) {
                            value = '';
                        } else {
                            value = value.trim();
                        }
                        if (value.length > 0) {
                            if (_this.is('select')) {
                                _this.parent().addClass('is-valid');
                                _this.parent().removeClass('is-invalid');
                            } else {
                                _this.closest('.form-control').addClass('is-valid');
                                _this.closest('.form-control').removeClass('is-invalid');
                            }
                            $('#connect-user').prop('disabled', false);
                        }
                    });
                }
            }
        });
    }
}
