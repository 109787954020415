var tourPurchaseInvoicesTableId = '#tour-purchase-invoices-datatable';
var tourExpensesTableId = '#tour-expenses-datatable';

function load_tours_datatable() {
    if (CURRENT_ACTION !== 'index') return;
    init_datatable('#tours-datatable', { 'search_area' : '#tour_search', 'order' : [[ 2, "desc" ]], 'min_filter_length' : 3 });
}

global.tour_purchase_invoices_datatable = function () {
    if($(tourPurchaseInvoicesTableId).length > 0) {
        $(tourPurchaseInvoicesTableId).DataTable().destroy();
    }
    init_datatable(tourPurchaseInvoicesTableId, { 'order' : [[ 0, "desc" ]], 'horizontal_scroll' : 'false' });
}

global.tour_expenses_datatable = function () {
    if($(tourExpensesTableId).length > 0) {
        $(tourExpensesTableId).DataTable().destroy();
    }
    init_datatable(tourExpensesTableId, { 'order' : [[ 0, "desc" ]], 'horizontal_scroll' : 'false' });
}

function tours_ready() {
    if (CURRENT_CONTROLLER !== 'tours') return;
    load_tours_datatable();
}
$(document).on('turbolinks:load', tours_ready);
