function EventGuest(method, view, event_id, event_guest_id) {
    this.method = method;
    this.url = '/events/' + event_id + '/event_guests/';

    if (event_guest_id !== undefined) {
        this.url += event_guest_id;
    }
    if (view === 'event') {
        this.datatable = $('#event-guests-datatable').dataTable();
    }
    this.edit_modal = $('#generic-edit-modal');
}

EventGuest.prototype.ajax_call = function(params) {
    var self = this;
    var data;
    if (params !== undefined) data = params;
    $.ajax({
        url         : self.url,
        type        : self.method,
        dataType    : 'json',
        data        : data,
        success     : function(data) {
            if (data.notice) {
                showNotification(data.notice);
                if (data.warn) showNotification(data.warn, 'warning');
                if (self.method !== 'DELETE') self.edit_modal.modal('hide');
                self.datatable.fnDraw(false);
            } else if (data.error) {
                showNotification(data.error, 'danger');
                if (self.method !== 'DELETE') $('#submit').prop('disabled',false);
            }
        },
        error : function (request, status, error) {
            showNotification(request.responseText, 'danger', 'Error in ajax call!');
        }
    });
};

global.load_event_guest_edit_form = function (element, event, redirect_code, header) {
    event.preventDefault();
    var target_url = $(element).attr('href');
    if (target_url.length) {
        $('#generic-edit-modal .modal-header h3').text(header);
        $('#generic-edit-modal').modal('show');
        $.ajax({
            url         : target_url,
            data        : {
                redirect_to_view : redirect_code
            },
            success     : function(data) {
                if (data.length) {
                    $('#generic-edit-modal .modal-container').html(data);
                    $('#event_guest_requested_by_person_id').selectpicker('refresh');
                }
            }
        });
    }
}

window.delete_event_guest = function(params) {
    if (params.length == 2) {
        var event_id = params[0];
        var event_guest_id = params[1];
        var event_guest = new EventGuest('DELETE', 'event', event_id, event_guest_id);
        event_guest.ajax_call();
    }
}

$(document).on('submit', '.event_guest_form', function(event) {
    event.preventDefault();
    var _this = $(this);
    var view = _this.data('view');
    var event_id = _this.data('event-id');
    var event_guest_id = _this.data('id');
    var ajax_method = 'POST';
    if (event_guest_id !== undefined) ajax_method = 'PUT';

    var event_guest = new EventGuest(ajax_method, view, event_id, event_guest_id);
    event_guest.ajax_call(_this.serialize());
});

var CUSTOMER_TYPE_PERSON    = 1;
var CUSTOMER_TYPE_COMPANY   = 2;

$(document).on('change', '.event_guest_customer_type input[type=radio]', function() {
    if($(this).val() == CUSTOMER_TYPE_PERSON) {
        $('.event_guest_first_name').removeClass('stashed');
        $('.event_guest_last_name').removeClass('stashed');
        $('.event_guest_name').addClass('stashed');
    }
    else {
        $('.event_guest_first_name').addClass('stashed');
        $('.event_guest_last_name').addClass('stashed');
        $('.event_guest_name').removeClass('stashed');
    }
});
