function load_invoices_datatable() {
    if (CURRENT_ACTION !== 'index') return;
    init_datatable('#invoices-datatable', { 'search_area' : '#invoice_search', 'order' : [[ 0, "asc" ]], 'min_filter_length' : 3 });
}

function invoices_ready() {
    if (CURRENT_CONTROLLER !== 'invoices') return;
    load_invoices_datatable();

    if (!['new','create','edit','update'].includes(CURRENT_ACTION)) return;
    init_customer_selector();

    $('#invoice_invoice_date').on('change', function() {
        update_due_date();
    });

    $('#invoice_payment_term_id').on('change', function(){
        update_due_date();
    });
}
$(document).on('turbolinks:load', invoices_ready);

function update_due_date(){
    var payment_term_id = $('#invoice_payment_term_id :selected').val();
    var days_to_be_added = Number($('#payment_term_' + payment_term_id).text());
    var invoice_date = $('#invoice_invoice_date').val();

    $('#invoice_due_date').val(addToDate(invoice_date, days_to_be_added, CURRENT_LOCALE));
}
