function ArtistPerson(method, view, artist_id, artist_person_id) {
    this.method = method;
    this.url = '/artists/' + artist_id + '/artist_persons/';

    if (artist_person_id !== undefined) {
        this.url += artist_person_id;
    }
    if (view === 'artist') {
        this.datatable = $('#artist-persons-datatable').dataTable();
    }
    this.edit_modal = $('#generic-edit-modal');
}

ArtistPerson.prototype.ajax_call = function(params) {
    var self = this;
    var data;
    if (params !== undefined) data = params;
    $.ajax({
        url         : self.url,
        type        : self.method,
        dataType    : 'json',
        data        : data,
        success     : function(data) {
            if (data.notice) {
                showNotification(data.notice);
                if (self.method !== 'DELETE') self.edit_modal.modal('hide');
                self.datatable.fnDraw(false);
            } else if (data.error) {
                showNotification(data.error, 'danger');
                if (self.method !== 'DELETE') $('#submit').prop('disabled',false);
            }
        },
        error : function (request, status, error) {
            showNotification(request.responseText, 'danger', 'Error in ajax call!');
        }
    });
};

global.load_artist_person_edit_form = function (element, event, redirect_code, header) {
    event.preventDefault();
    var target_url = $(element).attr('href');
    if (target_url.length) {
        $('#generic-edit-modal .modal-header h3').text(header);
        $('#generic-edit-modal').modal('show');
        $.ajax({
            url         : target_url,
            data        : {
                redirect_to_view : redirect_code
            },
            success     : function(data) {
                if (data.length) {
                    $('#generic-edit-modal .modal-container').html(data);
                    init_person_selector(false, true);
                    $(".selectpicker").selectpicker();
                }
            }
        });
    }
}

window.delete_artist_person = function(params) {
    if (params.length == 2) {
        var artist_id = params[0];
        var artist_person_id = params[1];
        var artist_person = new ArtistPerson('DELETE', 'artist', artist_id, artist_person_id);
        artist_person.ajax_call();
    }
}

$(document).on('submit', '.artist_person_form', function(event) {
    event.preventDefault();
    var _this = $(this);
    var view = _this.data('view');
    var artist_id = _this.data('artist-id');
    var artist_person_id = _this.data('id');
    var ajax_method = 'POST';
    if (artist_person_id !== undefined) ajax_method = 'PUT';

    var artist_person = new ArtistPerson(ajax_method, view, artist_id, artist_person_id);
    artist_person.ajax_call(_this.serialize());
});

var FEE_TYPE_PAYMENT        = 1;
var FEE_TYPE_EQUAL_SHARE    = 2;
var FEE_TYPE_FIXED_SHARE    = 3;
var FEE_TYPE_NO_FEE         = 4;

$(document).on('change', '#artist_person_fee_type_id', function() {
    var fee_type = $(this).find(":selected");
    if (fee_type.val() == FEE_TYPE_PAYMENT) {
        $('.artist_person_default_payment').removeClass('stashed');
        $('.artist_person_share_percent').addClass('stashed');
        $('.artist_person_total_compensation').removeClass('stashed');
    } else if (fee_type.val() == FEE_TYPE_FIXED_SHARE) {
        $('.artist_person_default_payment').addClass('stashed');
        $('.artist_person_share_percent').removeClass('stashed');
        $('.artist_person_total_compensation').addClass('stashed');
    } else {
        $('.artist_person_default_payment').addClass('stashed');
        $('.artist_person_share_percent').addClass('stashed');
        $('.artist_person_total_compensation').addClass('stashed');
    }
    if (fee_type.val() == FEE_TYPE_NO_FEE) {
        $('.artist_person_will_invoice').addClass('stashed');
    } else {
        $('.artist_person_will_invoice').removeClass('stashed');
    }
});
