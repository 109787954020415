var companyEmployeesTableId = '#company-employees-datatable';
var companyEventsTableId = '#company-events-datatable';

function load_companies_datatable() {
    if (CURRENT_ACTION !== 'index') return;
    init_datatable('#companies-datatable', { 'search_area' : '#company_search', 'order' : [[ 0, "asc" ]], 'min_filter_length' : 3 });
}

global.company_employees_datatable = function () {
    if($(companyEmployeesTableId).length > 0) {
        $(companyEmployeesTableId).DataTable().destroy();
    }
    init_datatable(companyEmployeesTableId, { 'order' : [[ 0, "asc" ]], 'horizontal_scroll' : 'false' });
}

global.company_events_datatable = function () {
    if($(companyEventsTableId).length > 0) {
        $(companyEventsTableId).DataTable().destroy();
    }
    init_datatable(companyEventsTableId, { 'order' : [[ 0, "desc" ]], 'horizontal_scroll' : 'false' });
}

function companies_ready() {
    if (CURRENT_CONTROLLER !== 'companies') return;
    load_companies_datatable();
}
$(document).on('turbolinks:load', companies_ready);

$(document).on('click', '#search-company-duplicates', function(event) {
    event.preventDefault();
    $.ajax({
        url 		: '/companies/' + $('#search-company-duplicates').attr('data-company-id') + '/compare_duplicates',
        type 		: 'GET',
        success 	: function(data) {
            $('#compare-duplicates-table').html(data);
        }
    });
});

$(document).on('change', '#duplicate_selector', function(event) {
    $('#compare-duplicates-table').fadeOut('fast');
    var other_company_id = $(this).val();
    $.ajax({
        url 		: '/companies/' + $('#search-company-duplicates').attr('data-company-id') + '/compare_duplicates',
        type 		: 'GET',
        data        : { other_company_id : other_company_id },
        success 	: function(data) {
            $('#compare-duplicates-table').fadeIn();
            $('#compare-duplicates-table').html(data);
        }
    });
});

// This variable is needed for checking duplicates when creating new data. And because with Webpack there are no global variables, this must be defined separately for each model.
var doIgnoreDuplicateCompanies = false;

$(document).on('click', '#ignore_duplicates', function(event) {
    doIgnoreDuplicateCompanies = true;
    $($(this).attr('data-target-form')).trigger('submit');
});

function check_for_duplicates(event) {
    var name = $('#company_name').val();
    var city = $('#company_city').val();
    var country_id = $('#company_country_id').val();
    if (name.length > 0 && !doIgnoreDuplicateCompanies) {
        event.preventDefault();
        $.ajax({
            dataType    : 'json',
            url 		: '/company_duplicate_search',
            type 		: 'GET',
            data        : {
                company : { name : name, city : city, country_id : country_id }
            },
            success 	: function(data) {
                if (data['has_duplicates']) {
                    $('#save-company').removeAttr('disabled');
                    $('.duplicates-table').html(data['duplicates_table']);
                    $('#duplicates-modal').modal('show');
                } else {
                    doIgnoreDuplicateCompanies = true;
                    $(event.target).submit();
                }
            }
        });
    }
}

$(document).on('submit', 'form#new_company', function(event) {
    check_for_duplicates(event);
});
