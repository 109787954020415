export function init_helpers() {

    if($('.notifications').length > 0){
        $('.notifications').each(function() {
            var notifications_element = $(this);
            if (notifications_element.hasClass('alert-success')) {
                setTimeout(function() {
                    if(notifications_element.hasClass('alert-success'))
                        notifications_element.slideUp();
                }, 3500);
                $('body').on('DOMMouseScroll mousewheel', function (e) {
                    if(e.originalEvent.detail > 0 || e.originalEvent.wheelDelta < 0) {
                        notifications_element.slideUp();
                    }
                });
            }
        });
    }

    // Toggle the side navigation
    $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        if ($(".sidebar").hasClass("toggled")) {
            $('.sidebar .collapse').collapse('hide');
        };
    });

    // Highlight active menu navigation item
    $("#accordionSidebar li.nav-item a").each(function(index, element) {
        var href = $(this).attr('href');
        var is_active;
        if (window.location.pathname === '/events_calendar') {
            is_active = (window.location.pathname === href);
        } else {
            is_active = window.location.pathname.includes(href);
        }
        if (is_active) {
            $(this).parent().addClass("active");
        }
    });
}
$(document).on('turbolinks:load', init_helpers);

global.clearLocalStorage = function() {
    localStorage.clear();
}

global.getVatPercent = function(vat_rate_id) {
    const vatRates = new Map();
    vatRates.set(1, 0);
    vatRates.set(2, 0.10);
    vatRates.set(3, 0.14);
    vatRates.set(4, 0.24);
    vatRates.set(5, 0.22);
    vatRates.set(6, 0.23);
    vatRates.set(7, 0.08);
    vatRates.set(8, 0.09);
    vatRates.set(9, 0.13);
    vatRates.set(10, 0.255);

    return vatRates.get(vat_rate_id);
}

// SMS message handlers

global.count_message_price = function(cover_message) {
    // How many bytes in a JavaScript string?
    // https://stackoverflow.com/questions/2219526/how-many-bytes-in-a-javascript-string

    var firstMessageMaxCount = 160;
    var nextMessagesMaxCount = 153;

    var hasUnicodeChars = false;
    for(var stringIndex = 0, endOfString = cover_message.length; stringIndex < endOfString; stringIndex++ ) {
        var codePoint = cover_message.charCodeAt( stringIndex );
        if( codePoint >= 0x100 ) {
            hasUnicodeChars = true;
            break;
        }
    }
    if (hasUnicodeChars) {
        firstMessageMaxCount = 70;
        nextMessagesMaxCount = 67;
    }

    var total_numbers = Number($('#sms_numbers_count').text());
    var price_per_unit = Number($('#sms_messages_unit_price').text());

    var message_fixed_length = Number($('#sms_message_fixed_length').text());
    var chars = cover_message.length + message_fixed_length;

    var messages;
    if (chars <= firstMessageMaxCount) {
        messages = 1;
    } else {
        messages = Math.ceil(chars / nextMessagesMaxCount);
    }

    var total_messages = messages * total_numbers;
    $('#sms_messages_count').text(total_messages);

    var total_price = (Math.round(total_messages * price_per_unit * 100) / 100);
    $('#sms_messages_total_price').text(total_price.toFixed(2).replace(".", ","));
}

global.count_sms_numbers = function() {
    var CONTACT_METHOD_SMS = 2;
    var sms_numbers = 0;
    $.each($(".person_contact_method .select > option:selected"), function(){
        if ($(this).val() == CONTACT_METHOD_SMS) {
            if ($(this).parents('.nested-fields').find('.person_do_send input[type=checkbox]').is(":checked")) {
                ++sms_numbers;
            }
        }
    });
    $('#sms_numbers_count').text(sms_numbers);
    count_message_price($('.message_cover_text').val());
    return sms_numbers;
}

global.update_sms_info = function() {
    var sms_number_count = count_sms_numbers();
    if (sms_number_count > 0)
        $('.sms-message-info').removeClass('stashed');
    else
        $('.sms-message-info').addClass('stashed');
}

$(document).on('change', '.person_contact_method select', function() {
    update_sms_info();
});

$(document).on('change', '.person_do_send input[type=checkbox]', function() {
    update_sms_info();
});

$(document).on('keyup', '.message_cover_text', function() {
    var cover_message = $(this).val();

    $('#sms_message_cover_text_length').text(cover_message.length);
    count_message_price(cover_message);
});

// Bloodhound typeahead selectors

global.init_customer_selector = function() {
    var customerSearch = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/customer_search.json?q=%QUERY',
            wildcard: '%QUERY'
        }
    });
    $('.customer_selector .typeahead').typeahead(null, {
        name: 'customer-search',
        display: 'value',
        source: customerSearch
    });
    $('.customer_selector').bind('typeahead:selected', function(obj, datum) {
        $('.selected_customer_id').val(datum.id);
        if (datum.special_info != undefined && datum.special_info.length > 1)
            showNotification(datum.special_info.replace('\n','<br>'), 'warning');
    });
    $('.customer_selector').bind('typeahead:autocompleted', function(obj, datum) {
        $('.selected_customer_id').val(datum.id);
        if (datum.special_info != undefined && datum.special_info.length > 1)
            showNotification(datum.special_info.replace('\n','<br>'), 'warning');
    });
    $('.customer_selector').change(function(){
        if($(this).find('input.tt-input').val().length == 0) {
            $('.selected_customer_id').val('');
        }
    });
}

global.init_person_selector = function(with_invoicing_info, with_employer_info, with_customer_number) {
    var remoteUrl = '/person_search.json?q=%QUERY&with_person_type=true';

    if (with_invoicing_info !== undefined && with_invoicing_info === true) {
        remoteUrl += '&with_payroll_criteria=true'
    }

    if (with_employer_info !== undefined && with_employer_info === true) {
        remoteUrl += '&with_employer=true'
    }

    if (with_customer_number !== undefined && with_customer_number === true) {
        remoteUrl += '&with_customer_number=true'
    }

    var personSearch = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: remoteUrl,
            wildcard: '%QUERY'
        }
    });
    $('.person_selector .typeahead').typeahead(null, {
        name: 'person-search',
        display: 'value',
        source: personSearch,
        limit: Infinity,
        minLength: 3
    });
    function set_values(element, data) {
        var selector_index = $(element).attr('data-index');
        var selector = '.person_selector';
        if (selector_index == undefined) {
            selector += ' .selected_person_id';
        } else {
            selector += ' .selected_' + selector_index + '_person_id';
        }
        $(selector).val(data.id);
        if (data.person_type_id !== undefined) {
            $('.person_type_select').selectpicker('val',data.person_type_id);
        }
        if (data.will_invoice !== undefined) {
            $('.will_invoice_checkbox').prop('checked', data.will_invoice == 1);
        }
        if (data.pension_scheme_id !== undefined) {
            if (data.pension_scheme_id !== null) {
                // If selected person has payroll criteria and pension scheme set, let's find the correct social cost scheme
                var foundSocialCostScheme = document.querySelector('.social_cost_scheme_select [data-pension-scheme-id="' + data.pension_scheme_id + '"]');
                if (foundSocialCostScheme) {
                    $('.social_cost_scheme_select').val(foundSocialCostScheme.value);
                }
            }
        }
    }
    $('.person_selector').bind('typeahead:selected', function(obj, datum) {
        set_values(this, datum);
    });
    $('.person_selector').bind('typeahead:autocompleted', function(obj, datum) {
        set_values(this, datum);
    });
    $('.person_selector').change(function(){
        if($(this).find('input.tt-input').val().length == 0) {
            $('.selected_person_id').val('');
        }
    });
}

global.init_agreement_person_selector = function() {
    var agreementPersonSearch = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/person_search.json?q=%QUERY&with_person_type=true&with_employer=true',
            wildcard: '%QUERY'
        }
    });
    $('.agreement_person_selector .typeahead').typeahead(null, {
        name: 'agreement-person-search',
        display: 'value',
        source: agreementPersonSearch
    });
    $('.agreement_person_selector').bind('typeahead:selected', function(obj, datum) {
        $('.selected_agreement_person_id').val(datum.id);
    });
    $('.agreement_person_selector').bind('typeahead:autocompleted', function(obj, datum) {
        $('.selected_agreement_person_id').val(datum.id);
    });
    $('.agreement_person_selector').change(function(){
        if($(this).find('input.tt-input').val().length == 0) {
            $('.selected_agreement_person_id').val('');
        }
    });
}

global.init_contact_person_selector = function() {
    var contactPersonSearch = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/person_search.json?q=%QUERY&with_person_type=true',
            wildcard: '%QUERY'
        }
    });
    $('.contact_person_selector .typeahead').typeahead(null, {
        name: 'contact-person-search',
        display: 'value',
        source: contactPersonSearch,
        limit: Infinity,
        minLength: 3
    });
    $('.contact_person_selector').bind('typeahead:selected', function(obj, datum) {
        var selector_index = $(this).attr('data-index');
        var selector = '.contact_person_selector';
        if (selector_index == undefined) {
            selector += ' .selected_contact_person_id';
        } else {
            selector += ' .selected_' + selector_index + '_contact_person_id';
        }
        $(selector).val(datum.id);
    });
    $('.contact_person_selector').bind('typeahead:autocompleted', function(obj, datum) {
        var selector_index = $(this).attr('data-index');
        var selector = '.contact_person_selector';
        if (selector_index == undefined) {
            selector += ' .selected_contact_person_id';
        } else {
            selector += ' .selected_' + selector_index + '_contact_person_id';
        }
        $(selector).val(datum.id);
    });
    $('.contact_person_selector').change(function(){
        if($(this).find('input.tt-input').val().length == 0) {
            $('.contact_person_selector .selected_contact_person_id').val('');
        }
    });
}

global.init_company_selector = function() {
    var companySearch = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/company_search.json?q=%QUERY',
            wildcard: '%QUERY'
        }
    });
    $('.company_selector .typeahead').typeahead(null, {
        name: 'company-search',
        display: 'value',
        source: companySearch,
        limit: Infinity,
        minLength: 3
    });
    $('.company_selector').bind('typeahead:selected', function(obj, datum) {
        var selector_index = $(this).attr('data-index');
        var selector = '.company_selector';
        if (selector_index == undefined) {
            selector += ' .selected_company_id';
        } else {
            selector += ' .selected_' + selector_index + '_company_id';
        }
        $(selector).val(datum.id);
    });
    $('.company_selector').bind('typeahead:autocompleted', function(obj, datum) {
        var selector_index = $(this).attr('data-index');
        var selector = '.company_selector';
        if (selector_index == undefined) {
            selector += ' .selected_company_id';
        } else {
            selector += ' .selected_' + selector_index + '_company_id';
        }
        $(selector).val(datum.id);
    });
    $('.company_selector').change(function(){
        if($(this).find('input.tt-input').val().length == 0) {
            $('.company_selector .selected_company_id').val('');
        }
    });
}

global.init_venue_selector = function() {
    var venueSearch = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/venue_search.json?q=%QUERY',
            wildcard: '%QUERY'
        }
    });
    $('.venue_selector .typeahead').typeahead(null, {
        name: 'venue-search',
        display: 'value',
        source: venueSearch,
        limit: Infinity,
        minLength: 3,
        templates: {
            suggestion: function (data) {
                var classes = 'tt-suggestion tt-selectable';
                if (data.own_venue == 1) classes = classes + ' own-venue';
                return '<div class="' + classes + '">' + data.value + '</div>';
            }
        }
    });
    $('.venue_selector').bind('typeahead:selected', function(obj, datum) {
        $('.selected_venue_id').val(datum.id);
        if (datum.special_info != undefined && datum.special_info.length > 1)
            showNotification(datum.special_info.replace('\n','<br>'), 'warning');
    });
    $('.venue_selector').bind('typeahead:autocompleted', function(obj, datum) {
        $('.selected_venue_id').val(datum.id);
        if (datum.special_info != undefined && datum.special_info.length > 1)
            showNotification(datum.special_info.replace('\n','<br>'), 'warning');
    });
    $('.venue_selector').change(function(){
        if($(this).find('input.tt-input').val().length == 0) {
            $('.selected_venue_id').val('');
        }
    });
}

global.init_city_selector = function() {
    var citySearch = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/city_search.json?q=%QUERY',
            wildcard: '%QUERY'
        }
    });
    $('.city_selector .typeahead').typeahead(null, {
        name: 'city-search',
        display: 'value',
        source: citySearch,
        limit: Infinity,
        minLength: 3,
        templates: {
            suggestion: function (data) {
                var classes = 'tt-suggestion tt-selectable';
                return '<div class="' + classes + '">' + data.value + '</div>';
            }
        }
    });
    $('.city_selector').bind('typeahead:selected', function(obj, datum) {
        $('.selected_city_id').val(datum.id);
    });
    $('.city_selector').bind('typeahead:autocompleted', function(obj, datum) {
        $('.selected_city_id').val(datum.id);
    });
    $('.city_selector').change(function(){
        if($(this).find('input.tt-input').val().length == 0) {
            $('.selected_city_id').val('');
        }
    });
}

global.init_hotel_selector = function() {
    var hotelSearch = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/hotel_search.json?q=%QUERY',
            wildcard: '%QUERY',
        }
    });
    $('.hotel_selector .typeahead').typeahead(null, {
        name: 'hotel-search',
        display: 'value',
        source: hotelSearch,
        limit: Infinity,
        minLength: 3
    });
    $('.hotel_selector').bind('typeahead:selected', function(obj, datum) {
        var selector_index = $(this).attr('data-index');
        var selector = '.hotel_selector';
        if (selector_index == undefined) {
            selector += ' .selected_hotel_id';
        } else {
            selector += ' .selected_' + selector_index + '_hotel_id';
        }
        $(selector).val(datum.id);
    });
    $('.hotel_selector').bind('typeahead:autocompleted', function(obj, datum) {
        var selector_index = $(this).attr('data-index');
        var selector = '.hotel_selector';
        if (selector_index == undefined) {
            selector += ' .selected_hotel_id';
        } else {
            selector += ' .selected_' + selector_index + '_hotel_id';
        }
        $(selector).val(datum.id);
    });
    $('.hotel_selector').change(function(){
        if($(this).find('input.tt-input').val().length == 0) {
            $('.selected_hotel_id').val('');
        }
    });
}

global.confirmation_bootbox = function (event, element) {
    var _this = $(element);
    event.preventDefault();

    var cancel_text;
    _this.data('cancel') != null ? cancel_text = _this.data('cancel') : cancel_text = 'Cancel';

    var ok_text;
    _this.data('ok') != null ? ok_text = _this.data('ok') : ok_text = 'OK';

    var method;
    _this.data('use-method') != null ? method = _this.data('use-method') : method = 'delete';

    var main_button_class = 'btn-primary';
    if (method == 'delete') main_button_class = 'btn-danger';

    var ajax;
    _this.data('use-ajax') != null ? ajax = true : ajax = false;

    var ajax_callback;
    ajax ? ajax_callback = _this.attr('data-ajax-callback') : ajax_callback = null;

    var cancel_callback;
    _this.attr('data-cancel-callback') != null ? cancel_callback = _this.attr('data-cancel-callback') : null;

    var params = null;
    if (ajax) {
        params = _this.attr('data-params').split(",");
    }

    bootbox.confirm({
        message: _this.data('confirmation'),
        buttons: {
            confirm: {
                label: ok_text,
                className: main_button_class
            },
            cancel: {
                label: cancel_text,
                className: 'btn-secondary'
            }
        },
        callback: function (result) {
            if (result) {
                if (ajax) {
                    window[ajax_callback](params);
                } else {
                    $(element).removeAttr('onclick');
                    $(element).attr('data-method', method);
                    $(element)[0].click();
                }
            } else {
                if (cancel_callback) {
                    window[cancel_callback]();
                }
            }
        },
        onEscape: function() {
            if (cancel_callback) {
                window[cancel_callback]();
            }
        }
    });
}

global.showNotification = function (message, type, title) {
    var notification_type = 'success';
    if (type !== undefined && type.length > 1) {
        // supported types: success, warning, info, danger
        notification_type = type;
    }
    var notification_title = '';
    if (title !== undefined && title.length > 1) {
        notification_title = '<strong>' + title + '</strong>';
    }

    $.notify({
        title: notification_title,
        message: message
    },{
        type: notification_type,
        placement: {
            from: "bottom",
            align: "left"
        },
        offset: {
            x: 249,
            y: 80
        },
        delay: 3000
    });
}

global.formatDate = function(year, month, day, locale) {
    if (locale == 'en') {
        return (month < 10 ? "0" : "") + month + "/" + (day < 10 ? "0" : "") + day + "/" + year;
    } else if (locale == 'sv') {
        return year + '-' + (month < 10 ? "0" : "") + month + "-" + (day < 10 ? "0" : "") + day;
    } else {
        return (day < 10 ? "0" : "") + day + "." + (month < 10 ? "0" : "") + month + "." + year;
    }
}

global.addToDate = function(date, days, locale) {
    var formatted_date = '';

    if (date && days && locale) {
        var new_date;
        var date_splitted;

        if (locale == 'en') {
            date_splitted = date.split('/');
            new_date = new Date(date_splitted[2], Number(date_splitted[0])-1, date_splitted[1]);
        } else if (locale == 'sv') {
            date_splitted = date.split('-');
            new_date = new Date(date_splitted[0], Number(date_splitted[1])-1, date_splitted[2]);
        } else {
            date_splitted = date.split('.');
            new_date = new Date(date_splitted[2], Number(date_splitted[1])-1, date_splitted[0]);
        }
        new_date.setDate(new_date.getDate() + days);
        formatted_date = formatDate(new_date.getFullYear(), Number(new_date.getMonth())+1, new_date.getDate(), locale);
    }
    return formatted_date;
}

global.getCurrentTime = function() {
    var now     = new Date();
    var hour    = now.getHours();
    var minute  = now.getMinutes();
    if(hour.toString().length == 1) {
        var hour = '0'+hour;
    }
    if(minute.toString().length == 1) {
        var minute = '0'+minute;
    }
    var current_time = hour+':'+minute;
    return current_time;
}

global.parseDecimalString = function(field) {
    var content = field.val().replace(',', '.');
    var amount = parseFloat(content);
    if (isNaN(amount)) amount = 0;
    return amount;
}

global.has_empty_required_fields = function(form, options) {
    var has_errors = false;
    var validate_invisible_fields = true;
    if (options) {
        if (options['ignore_invisible_fields']) {
            validate_invisible_fields = false;
        }
    }
    $(form).find('input.required, select.required, textarea.required').each(function(index, value) {
        var _this = $(this);
        var value = _this.val();
        var type = _this.attr('type');
        if ((type == 'radio' || type == 'checkbox')) {
            var name = _this.attr('name');
            var escaped_name = name.replace(/(:|\.|\[|\])/g,'\\$1');
            if ($('input[name='+ escaped_name +']:checked').length == 0) {
                value = '';
            }
        }
        if (value == null) {
            value = '';
        } else {
            value = value.trim();
        }
        if (value.length == 0 && (validate_invisible_fields || _this.is(":visible"))) {
            if (_this.is('select')) {
                _this.parent().addClass('is-invalid');
                _this.parent().removeClass('is-valid');
            } else {
                _this.closest('.form-control').addClass('is-invalid');
                _this.closest('.form-control').removeClass('is-valid');
            }
            has_errors = true;
        } else {
            _this.closest('.form-control').addClass('is-valid');
            _this.closest('.form-control').removeClass('is-invalid');
        }
    });
    return has_errors;
}

global.prevent_submit_unless_valid = function(form, event, options) {
    if (has_empty_required_fields(form, options)) {
        event.preventDefault();
    }
}

function onlyNumbers(evt) {
    // Allowed keycodes: numbers 0-9 (48-57), keypad numbers 0-9 (96-105), and comma or period
    // And options: backspace (8), tab (9), return (13), arrows (37-40)
    var allowKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
    var allowExceptions = [8, 9, 13, 37, 38, 39, 40];
    var length = 0;

    // And Select All (65), Cut (88), Copy (67), Paste (86) if control (ctrlKey in Windows) or command meta key (metaKey in Mac) has been pressed
    if (evt.metaKey || evt.ctrlKey) allowExceptions.push(65, 67, 86, 88);
    if (evt.target) length = evt.target.value.length;

    if ($.inArray(evt.key, allowKeys) === -1 && $.inArray(evt.keyCode, allowExceptions) === -1) {
        return false;
    } else {
        // If field value length is 10 or more numbers and key is not in allowed exceptions, prevent entering additional numbers
        if ($.inArray(evt.keyCode, allowExceptions) === -1 && length >= 10) return false;
    }
}
$(document).on('keydown', '.only_numbers', onlyNumbers);

function onlyDateTimeCharacters(evt) {
    // Allowed keycodes: numbers 0-9 (48-57), keypad numbers 0-9 (96-105), period . (46), semicolon : (Safari 186, Firefox 190)
    // And options: backspace (8), tab (9), return (13), arrows (37-40)
    var allowKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ':'];
    var allowExceptions = [8, 9, 13, 37, 38, 39, 40];

    // And Select All (65), Cut (88), Copy (67), Paste (86) if control (ctrlKey in Windows) or command meta key (metaKey in Mac) has been pressed
    if (evt.metaKey || evt.ctrlKey) allowExceptions.push(65, 67, 86, 88);

    if ($.inArray(evt.key, allowKeys) === -1 && $.inArray(evt.keyCode, allowExceptions) === -1) {
        return false;
    }
}
$(document).on('keydown', '.only_datetime_chars', onlyDateTimeCharacters);

$(document).on('click', '.reset_search_form', function(event){
    // Clear all search criteria from search forms
    event.preventDefault();
    $('.search-form').find('input:text, input[type=number], input:password, input:file, select, textarea').not('.multiselect-search').val('');

    // https://stackoverflow.com/questions/6169826/propchecked-false-or-removeattrchecked
    $('.search-form').find('input:radio, input:checkbox').removeAttr('checked').removeAttr('selected').prop('checked', false);

    $('.search-form').find('.hide_on_reset').hide();

    if($('.search-form .selectpicker').length != 0) {
        $('.search-form .selectpicker').each(function() {
            localStorage.removeItem($(this).attr('id'));
        });
        $('.search-form .search-form').find('.selectpicker.has-default-value').each(function(){
            var values = $(this).data('default-value');
            $(this).selectpicker('val', values);
        });
        $('.search-form .selectpicker').selectpicker('refresh');
    }

    if($('.ajax_selectpicker').length != 0) {
        $('.ajax_selectpicker').selectpicker('refresh');
    }

    var datatable = $('.search-form').attr('data-datatable');
    if($(datatable).length != 0)
        $(datatable).DataTable().search('').draw();
});

$(document).on('click', '.submit_search_form', function(event){
    event.preventDefault();
    var datatable = $('.search-form').attr('data-datatable');
    if($(datatable).length != 0)
        $(datatable).DataTable().draw();
});

$(document).on('keypress', '.disallow_instant_search', function(event) {
    if(event.which == 13) {
        $('.submit_search_form').trigger('click');
    }
});

function form_is_empty(form) {
    var is_empty = true;
    var fields = $(form + " input,select,trix-editor");
    fields.each(function() {
        if (['text','number','checkbox','select-one','select-multiple'].includes(this.type) || $(this).hasClass('trix-content')) {
            var hasValue = false;
            if (this.type == 'checkbox') {
                if (!this.name.includes('do_send')) { // Let's bypass certain checkboxes which are checked by default
                    hasValue = $(this).is(':checked');
                }
            } else if (this.type == 'select-one') {
                hasValue = $(this).val() > 0;
            } else if (this.type == 'select-multiple') {
                hasValue = $(this).val().length > 0;
            } else {
                hasValue = this.value.trim().length > 0;
            }
            if (hasValue) {
                is_empty = false;
                return false;
            }
        }
    });
    return is_empty;
}

$(document).on('click', '.confirm_if_form_filled a', function(event) {
    // Check first that there actually is a form with new_record_form CSS class
    if ($('form.new_record_form').length == 0) return true;

    var element = $('.confirm_if_form_filled');
    var targetHref = event.currentTarget.href;

    var ok_text;
    element.data('ok') != null ? ok_text = element.data('ok') : ok_text = 'OK';

    var cancel_text;
    element.data('cancel') != null ? cancel_text = element.data('cancel') : cancel_text = 'Cancel';

    if(!form_is_empty('form.new_record_form')) {
        bootbox.confirm({
            message: element.data('confirmation'),
            buttons: {
                confirm: {
                    label: ok_text,
                    className: 'btn-primary'
                },
                cancel: {
                    label: cancel_text,
                    className: 'btn-secondary'
                }
            },
            callback: function (result) {
                if (result) {
                    window.location = targetHref;
                }
            },
        });
        return false;
    }
});

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    $(".selectpicker").selectpicker()
})

document.addEventListener("turbolinks:before-cache", () => {
    $('.selectpicker').selectpicker('destroy').addClass('selectpicker')
    $('.typeahead').typeahead("destroy");
})

// typeahead field Font Awesome cross icon functions

$(document).on('click', '.clear_typeahead', function(){
    var _this = $(this);
    var field_to_clear = $(_this.data('clear-field'));
    if(field_to_clear) {
        field_to_clear.val('');
        _this.prev().find('input').val('');
        _this.hide();
    }
});

$(document).on('mouseenter', '.twitter-typeahead', function() {
    var _this = $(this);
    var clear_typeahead_element = _this.next('.clear_typeahead');
    if(clear_typeahead_element) {
        var field_to_clear = $(clear_typeahead_element.data('clear-field'));
        if(field_to_clear && field_to_clear.val()) {
            clear_typeahead_element.show();
        }
    }
});

$(document).on('mouseleave', '.twitter-typeahead', function(event) {
    if(!$(event.relatedTarget).hasClass('clear_typeahead')) {
        var _this = $(this);
        _this.next('.clear_typeahead').hide();
    }
});

$(document).keydown(function(event) {
    if ((event.ctrlKey || event.metaKey) && event.which == 83) {
        // Command or Control + S has been clicked (key code 83)
        var submitButton = $("form input[type=submit]");
        if (submitButton.length > 0) {
            event.preventDefault();
            submitButton.click();
            return false;
        }
    }
});

$(document).on('click', '#compare-duplicates-table td:nth-child(2), #compare-duplicates-table td:nth-child(3)', function(event) {
    var _this = $(this);
    var row = _this.parent();

    if (_this.hasClass('text_area')) {
        if (_this.hasClass('selected-cell') && row.find('.selected-cell').length > 1) {
            // Let's remove the clicked cell activation ONLY if both column cells have been activated
            _this.removeClass('selected-cell');
        } else {
            _this.addClass('selected-cell');
        }
    } else {
        row.find('td').removeClass('selected-cell');
        _this.addClass('selected-cell');
    }

    var hidden_field = row.find('input');
    if (hidden_field.length != 0) {
        if (_this.attr('data-cell-value')) {
            hidden_field.val(_this.attr('data-cell-value'));
        } else if (_this.hasClass('text_area')) {
            var combined_text = '';
            row.find('.selected-cell').each(function(){
                // Let's combine selected cell contents to the hidden field
                combined_text += $(this).text() + '\n\n';
            });
            hidden_field.val(combined_text);
        } else {
            hidden_field.val(_this.text());
        }
    } else {
        _this.removeClass('selected-cell');
    }
});
