function pushExtraDataFromContainer(data_container, container, datatable){
    var allow_instant_search = $(container).hasClass('disallow_instant_search') ? false : true;
    $(container + " input[type=checkbox]").each(function() {
        if($(this).attr('name') !== undefined && (!$(this).hasClass('must_be_checked') || $(this).is(':checked'))) {
            data_container.push({ "name": $(this).attr('name'), "value": $(this).attr('value') });
            // Let's bind the click function to refresh the datatable
        } else {
            $(this).addClass('ignore_input');
        }
        if(allow_instant_search)
            $(this).off('click').click(function(){ datatable.api().draw(); });
    });
    $(container + " input[type=radio]").each(function() {
        if($(this).attr('name') !== undefined && (!$(this).hasClass('must_be_checked') || $(this).is(':checked'))) {
            data_container.push({ "name": $(this).attr('name'), "value": $(this).attr('value') });
            // Let's bind the click function to refresh the datatable
        } else {
            $(this).addClass('ignore_input');
        }
        if(allow_instant_search)
            $(this).off('click').click(function(){ datatable.api().draw(); });
    });
    $(container + " select").each(function() {
        if(!$(this).hasClass('dont_push')){
            var select_element = $(this);
            if($(this).attr('multiple') == 'multiple') {
                var name = $(this).attr('name');
                $(this).find('option:selected').map(function() {
                    data_container.push({  "name": name, "value": $(this).val() });
                });
            } else {
                data_container.push({  "name": $(this).attr('name'), "value": $(this).find('option:selected').attr('value') });
            }
            // Let's bind the change function to refresh the datatable
            if(allow_instant_search) {
                $(this).off('change').change(function(){
                    if(select_element.hasClass('selectpicker')) {
                        select_element.selectpicker('refresh');
                    }
                    datatable.api().draw();
                });
            }
        }
    });
    $(container + " input").each(function() {
        if(!$(this).hasClass('ignore_input') && $(this).attr('name') != 'undefined') {
            // Let's push input name and value only if input's type is not radio (or if it is radio, it has to be checked)
            if($(this).attr('type') != 'radio' || ($(this).attr('type') == 'radio' && $(this).is(':checked'))) {
                data_container.push({ "name": $(this).attr('name'), "value": $(this).val() });
            }
            if($(this).hasClass('date_picker')) {
                if(allow_instant_search)
                    $(this).off('change').change(function(){ datatable.api().draw(); });
            } else {
                if(allow_instant_search)
                    $(this).off('keyup').keyup(function(){ datatable.api().draw(); });
            }
        }
    });
    $('.ignore_input').removeClass('ignore_input');
    return data_container;
}

function get_datatable_parameters(search_form, datatable) {
    var params = {};
    if(search_form) {
        var data = pushExtraDataFromContainer(new Array, search_form, datatable);
        $.each(data, function(index, value){
            if(value.value != '') {
                if(value.name && value.name.slice(-2) == '[]') {
                    var param_name = value.name.substring(2, value.name.length - 3)
                    if(params[param_name] && Array.isArray(params[param_name])) {
                        params[param_name].push(value.value);
                    } else {
                        params[param_name] = [value.value];
                    }
                } else {
                    var param_name = value.name.substring(2, value.name.length - 1)
                    params[param_name] = value.value;
                }
            }
        });
    }
    return params;
}

global.hideRowFromTable = function(table, clicked_element, notification) {
    var index = $(clicked_element).parent().parent().index()+1;
    $(table + ' tbody tr:nth-child(' + index + ')').remove();
    showNotification(notification);
}

global.init_datatable = function(datatable_name, options) {

    // Defaults
    var _this               = $(datatable_name);
    var ajax_url            = _this.data('source');
    var language_url        = '/datatables/' + CURRENT_LOCALE + '.json';
    var unsortable_columns  = [];
    var order               = [];
    var searchArea          = '';
    var draw_callback       = null;
    var minFilterLength     = 0;
    var paging_options      = [5, 10, 25, 50, 100];
    var pageLength          = 10;
    var horizontal_scroll   = true;

    if($(window).width() < 500)
        pageLength = 5;

    if(options) {
        if(options['unsortable_columns']) {
            if(options['unsortable_columns'] === 'last') {
                unsortable_columns = [Number(_this.find('th').length)-1];
            } else if(options['unsortable_columns'] === 'all') {
                _this.find('th').each(function(index){
                    unsortable_columns.push(index);
                });
            } else {
                unsortable_columns = options['unsortable_columns'];
            }
        }

        if(options['order']) {
            order = options['order'];
        }

        if(options['search_area']) {
            searchArea = options['search_area'];
            $('.search-form .selectpicker').selectpicker('refresh');
        }

        if(options['min_filter_length']) {
            minFilterLength = options['min_filter_length'];
        }

        if(options['page_length']) {
            pageLength = options['page_length'];
        }

        if(options['draw_callback']) {
            draw_callback = options['draw_callback'];
        } else {
            draw_callback = function () {
                $('[data-toggle="tooltip"]').tooltip();
            }
        }

        if(options['horizontal_scroll']) {
            if(options['horizontal_scroll'] === 'false') {
                horizontal_scroll = false;
            }
        }
    }

    var datatable = _this.dataTable({
        lengthMenu      : [paging_options, paging_options],
        pageLength      : pageLength,
        responsive      : { details : false },
        processing      : true,
        serverSide      : true,
        stateSave       : true,
        ajax            : {
            url         : ajax_url,
            data        : function(params) {
                params.q = get_datatable_parameters(searchArea, datatable);
            }
        },
        language        : { url : language_url },
        order           : order,
        pagingType      : 'full_numbers',
        columnDefs      : [ { orderable: false, targets: unsortable_columns } ],
        scrollX         : horizontal_scroll,
        drawCallback    : draw_callback,
        initComplete    : function () {
            // Read more https://datatables.net/examples/api/api_in_init.html
            if (minFilterLength > 0) {
                var datatable_api = this.api();
                $(".dataTables_filter input")
                    .unbind()                                       // Unbind previous default bindings
                    .bind("keyup", function(e) {                    // Bind our modified behavior
                        var value = this.value.trim();
                        if (value.length >= minFilterLength || e.keyCode == 13) {
                            // If the length is at least minFilterLength characters, or the user pressed ENTER
                            if ($.inArray(e.keyCode, [37, 38, 39, 40]) === -1) {
                                datatable_api.search(value).draw(); // Unless an arrow key was pressed, do the search
                            }
                        }
                        if  (value.length < 1) {
                            datatable_api.search("").draw();        // Ensure we clear the search if search value is empty
                        }
                        return;
                    });
            }
        }
    });
    return datatable;
}

// Following is needed to clear the datatable objects from turbolink cache

const myDataTables = [];

document.addEventListener("turbolinks:load", () => {
    if (myDataTables.length === 0 && $('.my-datatable').length !== 0) {
        $('.my-datatable').each((_, element) => {
            myDataTables.push($(element).DataTable());
        });
    }
});

document.addEventListener("turbolinks:before-cache", () => {
    while (myDataTables.length !== 0) {
        myDataTables.pop().destroy();
    }
});
