import { Calendar } from '@fullcalendar/core/';
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

import fiLocale from '@fullcalendar/core/locales/fi';
import svLocale from '@fullcalendar/core/locales/sv';

var eventCalendar;
var eventTaskCalendar;

global.refreshEventsCalendar = function() {
    eventCalendar.refetchEvents();
}

global.refreshEventTasksCalendar = function() {
    eventTaskCalendar.refetchEvents();
}

function update_event_task(event_task) {
    var success = false;

    let params = {
        event_task: {
            deadline_at: event_task.start.toISOString()
        }
    };

    $.ajax({
        url         : '/event_tasks/' + event_task.id,
        type        : 'PUT',
        dataType    : 'json',
        data        : params,
        async       : false,
        success     : function(data) {
            if (data.notice) {
                showNotification(data.notice);
                if (data.warn) showNotification(data.warn, 'warning');
                success = true;
            } else if (data.error) {
                showNotification(data.error, 'danger');
            }
        },
        error : function (request, status, error) {
            showNotification(request.responseText, 'danger', 'Error in ajax call!');
        }
    });
    return success;
}

function init_event_tasks_calendar() {
    var taskCalendarEl = document.getElementById('event_tasks_calendar');
    eventTaskCalendar = new Calendar(taskCalendarEl, {
        plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin ],
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        initialView: 'dayGridMonth',
        displayEventTime: true,
        navLinks: true, // can click day/week names to navigate views
        dayMaxEvents: true, // allow "more" link when too many events
        eventTimeFormat: { hour: '2-digit', minute: '2-digit', hour12: false },
        slotMinTime: "06:00",
        locales: [ fiLocale, svLocale ],
        locale: CURRENT_LOCALE,
        allDaySlot: true, // default true
        editable: true,
        events: '/event_tasks_calendar.json',
        eventClick: function(info) {
            info.jsEvent.preventDefault(); // don't let the browser navigate
            load_event_task_edit_form(info.event);
        },
        eventDrop: function(info) {
            if (!update_event_task(info.event)) {
                info.revert();
            }
        },
    });
    eventTaskCalendar.render();
}

function init_events_calendar() {
    var eventCalendarEl = document.getElementById('event_calendar');
    eventCalendar = new Calendar(eventCalendarEl, {
        plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin ],
        headerToolbar: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        initialView: 'dayGridMonth',
        displayEventTime: false,
        navLinks: true, // can click day/week names to navigate views
        dayMaxEvents: true, // allow "more" link when too many events
        eventTimeFormat: { hour: '2-digit', minute: '2-digit', hour12: false },
        slotMinTime: "06:00",
        locales: [ fiLocale, svLocale ],
        locale: CURRENT_LOCALE,
        allDaySlot: true, // default true
        events: '/events_calendar.json',
        eventClick: function(info) {
            info.jsEvent.preventDefault(); // don't let the browser navigate
            if (info.event.url) {
                window.open(info.event.url);
            }
        }
    });
    eventCalendar.render();
}

function event_calendar_ready() {
    if (!LOGGED_IN) return;

    if (CURRENT_CONTROLLER == 'events_calendar') {
        init_events_calendar();
    }

    if (CURRENT_CONTROLLER == 'event_tasks_calendar') {
        init_event_tasks_calendar();
    }
}
$(document).on('turbolinks:load', event_calendar_ready);